import { LogLevel } from "@azure/msal-browser";
import env from './environment';

export const msalConfig = {
    auth: {
        clientId: env.REACT_APP_AZUREAD_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/' + env.REACT_APP_AZUREAD_TENANT_ID, // Tenant ID of the React.JS App Registration
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: string, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
    scopes: [env.REACT_APP_AZUREAD_API_SCOPE],
};