import React from 'react'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {AdministratorRow} from "./AdministratorRow";
import {Loading} from "../../../../../components/Loading";
import {Administrator} from "../../../../../types/Administrators";
import {SortableTableHeadCell} from "../../../components/SortableTableHeadCell";
import {SortOrderTypes} from "../../../../../types/DatatableTypes";
import { CustomTableFooter } from '../../../../OrganiserPage/components/common/CustomTableFooter';

interface Props {
  admins: Administrator[],
  isLoading: boolean,
  onRefreshData: () => void,
  sortOrder: SortOrderTypes,
  onSort: (sortBy?: string) => void,
  sortBy: string
}

export const AdministratorsListTable: React.FC<Props> = (
  {admins, isLoading, onRefreshData, sortOrder, onSort, sortBy}
) => {

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <SortableTableHeadCell
              text="Name"
              columnName={"Name"}
              sortOrder={sortBy === 'Name' ? sortOrder : ''}
              onSort={onSort}
            />
            <SortableTableHeadCell
              text="Email"
              columnName={"Email"}
              sortOrder={sortBy === 'Email' ? sortOrder : ''}
              onSort={onSort}
              />
            <TableCell align="right">
              <span style={{marginRight: '12px'}}>Action</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(admins.length === 0 && !isLoading) &&
            <TableRow>
              <TableCell colSpan={8} align='center' sx={{color: '#aaa'}}>No data available</TableCell>
            </TableRow>
          }
          {
            admins.map((row, index) => (
              <AdministratorRow key={index} row={row} onRefreshData={onRefreshData}/>
            ))
          }
        </TableBody>
      </Table>
      { isLoading && <Loading />}
      <CustomTableFooter totalRows={admins.length} />
    </TableContainer>
  );
}
