import React from "react";
import { Button, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { RemoveCircleOutline } from "@mui/icons-material";
import { FeedbackRequest } from "../../../../types/FeedbackRequest";
import { WarningTooltip } from "../../../Tooltip";
import { InputContainerBox } from "../../../InputContainerBox";
import { isValidEmail } from "../../../../utils/ValidationHelpers";

interface Props {
  items: FeedbackRequest[],
  add: () => void,
  apply: (index: number, event: any) => void,
  remove: (index: number) => void,
  readonly: boolean,
  duplicates: any,
  canSubmit: boolean
}

const outlineInputPops = {
  maxLength: 100
};

const tableHeader = {
  width: 300
}

export const ClientFeedbackRequestDesktopView: React.FC<Props> = (
  { items, add, apply, remove, readonly, duplicates, canSubmit }) => {

  return (
    <TableContainer component={Paper} style={{ marginTop: 15 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={tableHeader} align="left">First and Last Name</TableCell>
            <TableCell style={tableHeader} align="left">Email Address</TableCell>
            <TableCell style={tableHeader} align="left">Company</TableCell>
            <TableCell style={tableHeader} align="left">Project or Work Title</TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((data, index) => {
              const { clientName, clientEmail, clientCompany, projectName } = data;
              return (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={index}
                >
                  <TableCell>
                    <InputContainerBox>
                      <OutlinedInput
                        inputProps={outlineInputPops}
                        disabled={readonly}
                        size='small'
                        autoComplete={'off'}
                        value={clientName}
                        name="clientName"
                        onChange={(event) => apply(index, event)} />
                      {!clientName && <WarningTooltip title={"Mandatory field."} />}
                    </InputContainerBox>
                  </TableCell>
                  <TableCell>
                    <InputContainerBox>
                      <OutlinedInput
                        inputProps={outlineInputPops}
                        disabled={readonly}
                        size='small'
                        autoComplete={'off'}
                        value={clientEmail}
                        name="clientEmail"
                        onChange={(event) => apply(index, event)} />
                      {!clientEmail ?
                        (<WarningTooltip title={"Mandatory field."} />) :
                        (isValidEmail(clientEmail) || (<WarningTooltip title={"Email is invalid."} />))}
                    </InputContainerBox>
                  </TableCell>
                  <TableCell align="left">
                    <InputContainerBox>
                      <OutlinedInput
                        inputProps={outlineInputPops}
                        disabled={readonly}
                        size='small'
                        autoComplete={'off'}
                        value={clientCompany}
                        name="clientCompany"
                        onChange={(event) => apply(index, event)} />
                      {!clientCompany && <WarningTooltip title={"Mandatory field."} />}
                    </InputContainerBox>
                  </TableCell>
                  <TableCell align="left">
                    <InputContainerBox>
                      <OutlinedInput
                        inputProps={outlineInputPops}
                        disabled={readonly}
                        size='small'
                        autoComplete={'off'}
                        value={projectName}
                        name="projectName"
                        onChange={(event) => apply(index, event)} />
                      {!projectName && <WarningTooltip title={"Mandatory field."} />}
                    </InputContainerBox>
                  </TableCell>
                  <TableCell align="left">
                    <Button disabled={readonly} onClick={() => remove(index)}>
                      <RemoveCircleOutline />
                    </Button>
                    {duplicates[index] && (<WarningTooltip title={"Duplicated entry at line: " + duplicates[index]} />)}
                  </TableCell>
                </TableRow>
              )
            })
          }
          {readonly && !items.length ?
            <TableRow>
              <TableCell colSpan={5}>
                <Typography sx={{ color: '#aaa' }}>No data available.</Typography>
              </TableCell>
            </TableRow>
            :
            (canSubmit &&
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {canSubmit && (
                    <Button disabled={readonly} onClick={add}>
                      <AddCircleOutlineIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};
