import * as React from 'react';
import Box from '@mui/material/Box';
import {StaffsDropdown} from "../SatffsDropdown";
import {Button} from "@mui/material";
import {Staff} from "../../../../../types/Staff";

interface Props {
  staffs: Staff[],
  selectedId: number,
  onSelect: (event: any) => void,
  onCloseDialog: () => void,
  canAdd: boolean,
  disabled: boolean,
  handleAddNewAdministrator: () => void
}

export const NewAdministratorDialogDesktopView: React.FC<Props> = (
  {staffs, selectedId, onSelect, onCloseDialog, canAdd, disabled, handleAddNewAdministrator}
) => {
  return (
    <Box sx={{
      m: 1,
      display: 'flex',
      gap: '8px',
      float: 'center'
    }}>
      <StaffsDropdown staffs={staffs} selectedId={selectedId} onSelect={onSelect}/>
      <Button
        variant="contained"
        onClick={onCloseDialog}
      >
        <strong>Cancel</strong>
      </Button>
      <Button
        disabled={!canAdd || disabled}
        variant="contained"
        onClick={handleAddNewAdministrator}
      >
        <strong>Add</strong>
      </Button>
    </Box>
  );
}