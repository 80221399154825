import React, {ReactNode} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Navigator from "./Navigator";
import Header from "./Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme";
import {Copyright} from "./Copyright";

interface Props {
  children: ReactNode;
}

const drawerWidth = 256;

export const AppLayout: React.FC<Props> = ({children}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isShowMenu = true;

  return (
    <Box sx={{display: 'flex', minHeight: '100vh'}}>
      <CssBaseline/>
      {isShowMenu &&
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{style: {width: drawerWidth}}}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}
          <Navigator
            PaperProps={{style: {width: drawerWidth}}}
            sx={{display: {sm: 'block', xs: 'none'}}}
          />
        </Box>}
      <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', width: '100%'}}>
        <Header onDrawerToggle={handleDrawerToggle}/>
        <Box component="main" sx={{flex: 1, p: 2, bgcolor: '#dadada'}}>
          <Box sx={{p: 2, bgcolor: '#f9f9f9', height: '100%'}}>
          {children}
          </Box>
        </Box>
        <Box component="footer" sx={{p: 2, bgcolor: '#eaeff1'}}>
          <Copyright/>
        </Box>
      </Box>
    </Box>
  );
};
