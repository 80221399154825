import {Button, IconButton} from '@mui/material';
import React from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

interface Props {
  isSmallScreen: boolean,
  onClickAction: (value: boolean) => void
}

export const ResponsiveAddButton: React.FC<Props> = (
  {isSmallScreen, onClickAction}
) => {
  return (
    <>
      {isSmallScreen
        ? <IconButton
          color="primary"
          sx={{float: "right"}}
          onClick={() => onClickAction(true)}>
          <AddBoxOutlinedIcon />
        </IconButton>
        : <Button
          sx={{float: "right", width: '120px', marginBottom: '10px'}}
          variant="contained"
          onClick={() => onClickAction(true)}>Add
        </Button>
      }
    </>
  );
}