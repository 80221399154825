import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { ClientSummaryUpdateModel, FeedbackRequest } from '../../../../types/FeedbackRequest';
import { ChildTable } from './ChildTable';
import { ClientSummaryModel } from '../../../../types/ClientSummaryModel';
import { getFeedbackRequestsByClient, updateClientSummary } from '../../../../services/FeedbackRequestService';
import { Button, OutlinedInput } from '@mui/material';
import { WarningTooltip } from '../../../../components/Tooltip';
import { enqueueSnackbar } from 'notistack';
import { InputContainerBox } from '../../../../components/InputContainerBox';
import { isValidEmail } from '../../../../utils/ValidationHelpers';
import { useConfirm } from 'material-ui-confirm';
import { ConfirmCancelMessage } from '../common/ConfirmCancelMessage';
import {StatusNotesDropdown} from "./StatusNotesDropdown";

type CustomTableRowProps = {
  periodId: number,
  summaryItem: ClientSummaryModel,
  onDeleted: () => void,
  onSaved: () => void,
  onGenerateEmailClick: () => void
}

export function CustomTableRow(props: CustomTableRowProps) {
  const { summaryItem } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [feedbackRequests, setFeedbackRequests] = React.useState<FeedbackRequest[]>([]);

  React.useEffect(() => {
    setIsExpanded(false);
    setFeedbackRequests([]);
  }, [setFeedbackRequests, setIsExpanded, props.periodId]);

  const expandRow = () => {
    setIsExpanded(!isExpanded);
    loadData();
  }

  const loadData = (forced: boolean = false) => {
    if (forced || (feedbackRequests.length === 0 && summaryItem.totalRequest > 0)) {
      setIsLoading(true);
      getFeedbackRequestsByClient(props.periodId, summaryItem.clientEmail)
        .then((res) => {
          setFeedbackRequests(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setFeedbackRequests([]);
          setIsLoading(false);
        });
    }
  }

  const handleSaved = () => {
    setIsEditMode(false);
    props.onSaved();
  };

  return (
    <>
      <TableRow sx={{ borderBottom: 0 }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={expandRow}
            sx={{ padding: 0 }}
            disabled={summaryItem.totalRequest === 0}
          >
            {summaryItem.totalRequest === 0 ? <HorizontalRuleIcon /> : (isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
          </IconButton>
        </TableCell>
        {isEditMode ?
          <EditableCustomTableCells
            periodId={props.periodId}
            summaryItem={summaryItem}
            onCancelClick={() => setIsEditMode(false)}
            onSaved={handleSaved}
            onGenerateEmailClick={props.onGenerateEmailClick} />
          :
          <ReadonlyCustomTableCells
            summaryItem={summaryItem}
            onEditClick={() => setIsEditMode(true)}
            onGenerateEmailClick={props.onGenerateEmailClick} />
        }
      </TableRow>
      {isExpanded &&
        <TableRow sx={{ backgroundColor: '#d5b2e6' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0, paddingLeft: 4 }} colSpan={8}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <ChildTable isLoading={isLoading} feedbackRequests={feedbackRequests} />
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </>
  );
}

function ReadonlyCustomTableCells(props: { summaryItem: ClientSummaryModel, onEditClick: () => void, onGenerateEmailClick: () => void }) {
  const { summaryItem } = props;

  return (
    <>
      <TableCell>{summaryItem.clientName}</TableCell>
      <TableCell>{summaryItem.company}</TableCell>
      <TableCell sx={{ maxWidth: 250, wordWrap: 'break-word' }}>{summaryItem.clientEmail}</TableCell>
      <TableCell align='right'>{summaryItem.totalRequest}</TableCell>
      <TableCell><Button variant='outlined' size='small' onClick={props.onGenerateEmailClick}>Generate</Button></TableCell>
      <TableCell>{summaryItem.statusNote}</TableCell>
      <TableCell align="right"><Button size='small' onClick={props.onEditClick}>Edit</Button></TableCell>
    </>
  );
}

type EditableCustomTableCellsProps = {
  summaryItem: ClientSummaryModel,
  periodId: number,
  onCancelClick: () => void,
  onSaved: () => void,
  onGenerateEmailClick: () => void
}

function EditableCustomTableCells(props: EditableCustomTableCellsProps) {
  const { summaryItem } = props;
  const [clientName, setClientName] = React.useState<string>(summaryItem.clientName);
  const [company, setCompany] = React.useState<string>(summaryItem.company);
  const [clientEmail, setClientEmail] = React.useState<string>(summaryItem.clientEmail);
  const [statusNote, setStatusNote] = React.useState<string>(summaryItem.statusNote);

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  
  const confirm = useConfirm();

  const handleSaveClick = () => {
    if (isInputsValid() === false) {
      return;
    }

    const data: ClientSummaryUpdateModel = {
      originalClientEmail: props.summaryItem.clientEmail,
      periodId: props.periodId,
      clientName: clientName,
      clientEmail: clientEmail,
      company: company,
      statusNote: statusNote
    };

    setIsSaving(true);
    updateClientSummary(data)
      .then((res) => {
        enqueueSnackbar('Update client summary successfully!', { variant: 'success' });
        props.onSaved();
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const handleCancelClick = () => {
    const isDirty = clientName !== summaryItem.clientName || company !== summaryItem.company || statusNote !== summaryItem.statusNote || clientEmail !== summaryItem.clientEmail;
    if (isDirty) {
      confirm({ title: 'Cancel', description: ConfirmCancelMessage })
        .then(() => props.onCancelClick());
    }
    else {
      props.onCancelClick();
    }
  }

  const isInputsValid = () => {
    return !!clientName && !!company && isValidEmail(clientEmail);
  }

  return (
    <>
      <TableCell>
        <InputContainerBox>
          <OutlinedInput
            disabled={isSaving}
            size='small'
            value={clientName}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setClientName(event.target.value)} />
          {!clientName && <WarningTooltip title={"Mandatory field."} />}
        </InputContainerBox>
      </TableCell>
      <TableCell align="left">
        <InputContainerBox>
          <OutlinedInput
            disabled={isSaving}
            size='small'
            value={company}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setCompany(event.target.value)} />
          {!company && <WarningTooltip title={"Mandatory field."} />}
        </InputContainerBox>
      </TableCell>
      <TableCell>
        <InputContainerBox>
          <OutlinedInput
            disabled={isSaving}
            size='small'
            value={clientEmail}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setClientEmail(event.target.value)} />
          {!clientEmail ?
            (<WarningTooltip title={"Mandatory field."} />) :
            (isValidEmail(clientEmail) || (<WarningTooltip title={"Email is invalid."} />))}
        </InputContainerBox>
      </TableCell>
      <TableCell align='right'>{summaryItem.totalRequest}</TableCell>
      <TableCell><Button variant='outlined' size='small' onClick={props.onGenerateEmailClick}>Generate</Button></TableCell>
      <TableCell align="left">
        <StatusNotesDropdown initValue={statusNote} onchangeStatusNotes={setStatusNote}/>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 180 }}>
        <Button size='small' onClick={handleSaveClick} disabled={isSaving || isInputsValid() === false}>{isSaving ? 'Saving...' : 'Save'}</Button>
        <Button size='small' onClick={handleCancelClick} disabled={isSaving}>Cancel</Button>
      </TableCell>
    </>
  );
}