import React from "react";
import { Box, IconButton, List, ListItem, OutlinedInput, Paper, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { RemoveCircleOutline } from "@mui/icons-material";
import { InputContainerBox } from "../../../InputContainerBox";
import { WarningTooltip } from "../../../Tooltip";
import { FeedbackRequest } from "../../../../types/FeedbackRequest";
import { isValidEmail } from "../../../../utils/ValidationHelpers";

interface Props {
  items: FeedbackRequest[],
  add: () => void,
  apply: (index: number, event: any) => void,
  remove: (index: number) => void,
  readonly: boolean,
  duplicates: any,
  canSubmit: boolean
}

const outlineInputPops = {
  maxLength: 100
};

const inputStyles = {
  paddingRight: 0,
  width: 'calc(100% - 20px)'
}

export const ClientFeedbackRequestMobileView: React.FC<Props> = (
  { items, add, apply, remove, readonly, duplicates, canSubmit }) => {

  return (
    <Paper sx={{ width: '100%', mt: 1 }}>
      <List dense sx={{ width: '100%', p: 0 }}>
        {
          items.map((data, index) => {
            const { clientName, clientEmail, clientCompany, projectName } = data;
            return (
              <ListItem
                divider
                key={index}
                disablePadding
                sx={{ p: 2, pt: 1, pr: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
              >
                {!readonly &&
                  <Box sx={{ height: 20 }}>
                    <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                      <IconButton sx={{ pr: 2 }} color="primary" disabled={readonly} onClick={() => remove(index)}>
                        <RemoveCircleOutline />
                      </IconButton>
                    </Box>
                    <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                      {duplicates[index] && (<WarningTooltip title={"Duplicated entry at line: " + duplicates[index]} />)}
                    </Box>
                  </Box>
                }

                <Typography variant="caption" sx={{ mt: 1 }}>First and Last Name</Typography>
                <InputContainerBox>
                  <OutlinedInput
                    inputProps={outlineInputPops}
                    disabled={readonly}
                    size='small'
                    sx={inputStyles}
                    autoComplete={'off'}
                    value={clientName}
                    name="clientName"
                    onChange={(event) => apply(index, event)} />
                  {!clientName && <WarningTooltip title={"Mandatory field."} />}
                </InputContainerBox>

                <Typography variant="caption" sx={{ mt: 1 }}>Email Address</Typography>
                <InputContainerBox>
                  <OutlinedInput
                    inputProps={outlineInputPops}
                    disabled={readonly}
                    size='small'
                    sx={inputStyles}
                    autoComplete={'off'}
                    value={clientEmail}
                    name="clientEmail"
                    onChange={(event) => apply(index, event)} />
                  {!clientEmail ?
                    (<WarningTooltip title={"Mandatory field."} />) :
                    (isValidEmail(clientEmail) || (<WarningTooltip title={"Email is invalid."} />))}
                </InputContainerBox>

                <Typography variant="caption" sx={{ mt: 1 }}>Company</Typography>
                <InputContainerBox>
                  <OutlinedInput
                    inputProps={outlineInputPops}
                    disabled={readonly}
                    size='small'
                    sx={inputStyles}
                    autoComplete={'off'}
                    value={clientCompany}
                    name="clientCompany"
                    onChange={(event) => apply(index, event)} />
                  {!clientCompany && <WarningTooltip title={"Mandatory field."} />}
                </InputContainerBox>

                <Typography variant="caption" sx={{ mt: 1 }}>Project or Work Title</Typography>
                <InputContainerBox>
                  <OutlinedInput
                    inputProps={outlineInputPops}
                    disabled={readonly}
                    size='small'
                    sx={inputStyles}
                    autoComplete={'off'}
                    value={projectName}
                    name="projectName"
                    onChange={(event) => apply(index, event)} />
                  {!projectName && <WarningTooltip title={"Mandatory field."} />}
                </InputContainerBox>
              </ListItem>
            )
          })
        }
        {readonly && !items.length ?
          <Typography variant="body2" sx={{ p: 2, color: '#aaa' }}>No data available.</Typography>
          :
          (canSubmit &&
            <ListItem key='-1' sx={{ py: 1 }}>
              <IconButton sx={{ p: 0 }} color="primary" disabled={readonly} onClick={add}>
                <AddCircleOutlineIcon />
              </IconButton>
            </ListItem>
          )
        }
      </List>
    </Paper>
  );
};
