import { Box, Typography } from "@mui/material";

export function CustomTableFooter(props: { totalRows: number }) {

  return (
    <>
      {
        props.totalRows > 0 &&
        <Box sx={{ width: '100%', textAlign: 'right', px: 2, py: 1 }}>
          <Typography variant='body2'>
            Total rows: {props.totalRows}
          </Typography>
        </Box>
      }
    </>
  );
}