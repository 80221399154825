import * as React from 'react';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { getGeneratedEmail } from '../../../../services/FeedbackRequestService';
import { Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import { EmailTemplateModel } from '../../../../types/EmailTemplateModel';
import {Button, Grid, InputAdornment, OutlinedInput} from '@mui/material';
import { Loading } from '../../../../components/Loading';
import {useState} from "react";
import {enqueueSnackbar} from "notistack";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

function CustomDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

type EmailContentDialogProps = {
  isOpen: boolean,
  periodId: number,
  clientEmail: string,
  onClose: () => void
}

export default function EmailContentDialog(props: EmailContentDialogProps) {
  const [emailTemplate, setEmailTemplate] = React.useState<EmailTemplateModel | undefined>(undefined);
  const [copiedContent, setCopiedContent] = useState(false);
  const [copiedSubject, setCopiedSubject] = useState(false);

  React.useEffect(() => {
    if (props.clientEmail) {
      getGeneratedEmail(props.periodId, props.clientEmail)
        .then(response => {
          setEmailTemplate(response);
        });
    }
  }, [props.periodId, props.clientEmail]);

  const handleClose = () => {
    props.onClose();
  };

  const copyContent = () => {
    copyToClip(emailTemplate!.content);
    setCopiedContent(true);
    enqueueSnackbar('Copied Email Content', {variant: 'success', autoHideDuration: 1500})
    setTimeout(() => setCopiedContent(false), 2000);
  }

  const copySubject = () => {
    copyToClip(emailTemplate!.subject);
    setCopiedSubject(true);
    enqueueSnackbar('Copied Email Subject', {variant: 'success', autoHideDuration: 1500})
    setTimeout(() => setCopiedSubject(false), 2000);
  }

  function copyToClip(str: string) {
    function listener(e: any) {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str.replace(/<[^>]+>/g, '\n'));
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }

  return (
    <>
      <CustomDialog
        maxWidth='lg'
        onClose={handleClose}
        open={props.isOpen}
      >
        <CustomDialogTitle onClose={handleClose}>
          Generate Email
        </CustomDialogTitle>
        <DialogContent dividers>
          {!emailTemplate ?
            <Loading />
            :
            <Grid container rowSpacing={2} columns={10}>
              <Grid item md={1} xs={10}>
                <Typography>
                  Subject:
                </Typography>
              </Grid>
              <Grid item md={9} xs={10}>
                <OutlinedInput size='small' sx={{ width: '100%', paddingRight: '0px'}} value={emailTemplate?.subject} readOnly
                  endAdornment={
                    <InputAdornment position="end">
                      <Button size={"small"} onClick={copySubject}>{copiedSubject ? 'Copied!' : 'Copy'}</Button>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item md={1} xs={10}>
                <Typography>
                  Content:
                </Typography>
              </Grid>
              <Grid item md={9} xs={10}>
                {emailTemplate &&
                  <>
                    <EditorProvider>
                      <Editor
                        disabled
                        value={emailTemplate.content}
                        containerProps={{ style: { minHeight: '300px', backgroundColor: '#fff'} }}
                      >
                        <Toolbar style={{display: 'flex'}}>
                          <Button sx={{marginLeft: 'auto'}} size={"small"} onClick={copyContent}>{copiedContent ? 'Copied!' : 'Copy'}</Button>
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                  </>
                }
              </Grid>
            </Grid>
          }
        </DialogContent>
      </CustomDialog>
    </>
  );
}