import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "@mui/material/styles";
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosError } from "axios";
import { msalConfig, loginApiRequest } from "./authConfig";
import env from './environment';
import { enqueueSnackbar } from 'notistack';
import { SnackbarMessageProvider } from "./context/SnackbarMessageContext";
import { ConfirmProvider } from "material-ui-confirm";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

const msalInstance = new PublicClientApplication(msalConfig);

axios.defaults.baseURL = env.REACT_APP_BACKEND_API_URL;

axios.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginApiRequest,
      account: account,
    });
    config.headers["Authorization"] = `Bearer ${msalResponse.accessToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => response, (error: AxiosError) => {
  const data: any = error.response?.data;
  enqueueSnackbar(data?.message ?? data?.errorMessage ?? 'An error occurred! Please contact the administrator!', { variant: 'error' });
  throw error;
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarMessageProvider>
        <ConfirmProvider>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </ConfirmProvider>
      </SnackbarMessageProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
