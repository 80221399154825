import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { FeedbackRequest } from '../../../../types/FeedbackRequest';
import { StaffRequestSummaryModel } from '../../../../types/StaffRequestSummaryModel';
import { getAssignedFeedbackRequestsToStaff } from '../../../../services/FeedbackRequestService';
import { ChildTable } from './ChildTable';

export function CustomTableRow(props: { periodId: number, staff: StaffRequestSummaryModel, onDeleted: () => void }) {
  const { staff } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [feedbackRequests, setFeedbackRequests] = React.useState<FeedbackRequest[]>([]);

  React.useEffect(() => {
    setIsExpanded(false);
    setFeedbackRequests([]);
  }, [setFeedbackRequests, setIsExpanded, props.periodId]);

  const expandRow = () => {
    setIsExpanded(!isExpanded);
    loadData();
  }

  const loadData = (forced: boolean = false) => {
    if (forced || (feedbackRequests.length === 0 && staff.totalRequest > 0)) {
      setIsLoading(true);
      getAssignedFeedbackRequestsToStaff(props.periodId, staff.staffId)
        .then((res) => {
          setFeedbackRequests(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setFeedbackRequests([]);
          setIsLoading(false);
        });
    }
  }

  const handleDelete = () => {
    loadData(true);
    props.onDeleted();
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={expandRow}
            sx={{ padding: 0 }}
            disabled={staff.totalRequest === 0}
          >
            {staff.totalRequest === 0 ? <HorizontalRuleIcon /> : (isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
          </IconButton>
        </TableCell>
        <TableCell>
          {staff.staffName} {!!staff.preferredName ? ` (${staff.preferredName})` : null}
        </TableCell>
        <TableCell align='right'>{staff.totalRequest}</TableCell>
      </TableRow>
      {isExpanded &&
        <TableRow sx={{ backgroundColor: '#d5b2e6' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0, paddingLeft: 4 }} colSpan={6}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <ChildTable isLoading={isLoading} feedbackRequests={feedbackRequests} onDataChanged={handleDelete} />
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </>
  );
}
