import * as React from 'react';
import Box from '@mui/material/Box';
import {Button, TextField, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {toDate, UI_DATE_PATTERN} from "../../../../../commons/dates";
import {PeriodStatusDropdown} from "../StatusDropdown";
import {WarningTooltip} from "../../../../../components/Tooltip";

interface Props {
  newStartDate: string,
  handleStartDateChange: (value: Date | null) => void,
  newEndDate: string,
  handleEndDateChange: (value: Date | null) => void,
  isActive: boolean,
  handleStatusChange: (status: boolean) => void,
  onCloseDialog: () => void,
  handleAddNewPeriod: () => void,
  canAdd: boolean,
  disabled: boolean
}

export const NewPeriodDialogDesktopView: React.FC<Props> = (
  {newStartDate, handleStartDateChange, newEndDate, handleEndDateChange, isActive, handleStatusChange, onCloseDialog, handleAddNewPeriod, canAdd, disabled}
) => {
  return (
    <>
      <Box sx={{
        display: "flex",
        gap: "15px",
        m: 2
      }}>
        <Typography sx={{width: "85px", fontWeight: "bold", marginTop: "5px"}}>
          Date range:
        </Typography>
        <DatePicker
          label="Start Date"
          value={toDate(newStartDate)}
          onChange={(value) => handleStartDateChange(value)}
          renderInput={(props) =>
            <TextField onKeyDown={(e) => e.preventDefault()}  {...props} size='small' />}
          inputFormat={UI_DATE_PATTERN}
        />
        <Typography sx={{marginTop: '5px'}} >-</Typography>
        <DatePicker
          label="End Date"
          value={toDate(newEndDate)}
          onChange={(value) => handleEndDateChange(value)}
          renderInput={(props) =>
            <TextField onKeyDown={(e) => e.preventDefault()}  {...props} size='small' />}
          inputFormat={UI_DATE_PATTERN}
        />
      </Box>
      <Box sx={{
        m: 2,
        display: "flex",
        gap: "28px",
      }}>
        <Typography sx={{width: "85px", fontWeight: "bold", marginTop: "5px"}}>
          Status:
        </Typography>
        <PeriodStatusDropdown initStatus={isActive} onChangeStatus={handleStatusChange}/>
      </Box>
      <Box sx={{ml: 2}}>
        {!canAdd && (<WarningTooltip
          placement={"right"}
          title={"The start date should be greater than now and less than end date."}/>)}
      </Box>
      <Box sx={{
        float: "right",
        display: "flex",
        gap: "15px",
        m: 2
      }}>
        <Button
          variant="contained"
          onClick={onCloseDialog}
        >
          <strong>Cancel</strong>
        </Button>
        <Button
          disabled={!canAdd || disabled}
          variant="contained"
          onClick={handleAddNewPeriod}
        >
          <strong>Add</strong>
        </Button>
      </Box>
    </>
  );
}