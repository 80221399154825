import * as React from 'react';
import { NumberFilterOperator } from '../../../../types/DatatableTypes';
import {Box, Divider, IconButton, MenuItem, OutlinedInput, Select} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Clear } from '@mui/icons-material';
import {StatusNotesDropdown} from "../ClientSummaryTable/StatusNotesDropdown";

type TextFilterInputProps = {
  onChange: (value: string) => void
}

export function TextFilterInput(props: TextFilterInputProps) {
  const [value, setValue] = React.useState('');

  const handleInputValueChange = (inputValue: string) => {
    setValue(inputValue);
    props.onChange(inputValue);
  }

  return (
    <OutlinedInput
      startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
      sx={{ borderBottom: 0, fontSize: '0.8rem', pr: 0, pl: 1 }}
      size='small'
      fullWidth
      value={value}
      endAdornment={
        <>
          <IconButton size='small' disabled={value === ''} onClick={() => handleInputValueChange('')}>
            <Clear fontSize='small' />
          </IconButton>
        </>
      }
      onChange={(e) => handleInputValueChange(e.target.value)} />
  );
}

type NumberFilterInputProps = {
  onChange: (value: number | '', operator: NumberFilterOperator) => void,
}

export function NumberFilterInput(props: NumberFilterInputProps) {
  const [value, setValue] = React.useState<number | ''>('');
  const [operator, setOperator] = React.useState(NumberFilterOperator.Equals);

  const handleInputValueChange = (inputValue: string) => {
    const newValue = (inputValue === '' || Number.isNaN(Number(inputValue))) ? '' : Number(inputValue);
    setValue(newValue);
    props.onChange(newValue, operator);
  }

  const handleOperatorChange = (inputOperator: NumberFilterOperator) => {
    setOperator(inputOperator);
    props.onChange(value, inputOperator);
  }

  return (
    <OutlinedInput
      inputProps={{ style: { textAlign: 'right' } }}
      sx={{ borderBottom: 0, fontSize: '0.8rem', pr: 0, pl: 1 }}
      size='small'
      fullWidth
      value={value}
      onChange={e => handleInputValueChange(e.target.value.trim())}
      startAdornment={
        <>
          <FilterListIcon />
          <Divider orientation="vertical" />
          <Select
            value={operator}
            onChange={e => handleOperatorChange(Number(e.target.value))}
            size='small'
            autoFocus={false}
            sx={{
              maxWidth: 140,
              fontSize: '0.8rem',
              borderRadius: 0,
              '.MuiSelect-select': {pl: 1},
              '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
            }} >
            <MenuItem value={NumberFilterOperator.Equals}>Equals</MenuItem>
            <MenuItem value={NumberFilterOperator.NotEqual}>Not equal</MenuItem>
            <MenuItem value={NumberFilterOperator.LessThan}>Less than</MenuItem>
            <MenuItem value={NumberFilterOperator.LessThanOrEquals}>Less than or equals</MenuItem>
            <MenuItem value={NumberFilterOperator.GreaterThan}>Greater than</MenuItem>
            <MenuItem value={NumberFilterOperator.GreaterThanOrEquals}>Greater than or equals</MenuItem>
          </Select>
        </>
      }
      endAdornment={
        <>
          <IconButton size='small' disabled={value === ''} onClick={() => handleInputValueChange('')}>
            <Clear fontSize='small' />
          </IconButton>
        </>
      }
    />
  );
}

type DropdownFilterInputProps = {
  onChange: (value: string) => void,
  filterOptions: any[]
}

export function DropdownFilterInput(props: DropdownFilterInputProps) {
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleInputValueChange = (inputValue: string) => {
    setSelectedValue(inputValue);
    props.onChange(inputValue);
  }

  return (
    <OutlinedInput
      inputProps={{ style: { textAlign: 'right' } }}
      sx={{ borderBottom: 0, fontSize: '0.8rem', pr: 0, pl: 1 }}
      size='small'
      fullWidth
      startAdornment={
        <>
          <FilterListIcon />
          <Divider orientation="vertical" />
          <Select
            value={selectedValue}
            onChange={e => handleInputValueChange(e.target.value)}
            size='small'
            autoFocus={false}
            sx={{
              maxWidth: 140,
              fontSize: '0.8rem',
              borderRadius: 0,
              '.MuiSelect-select': {pl: 1},
              '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
            }} >
            {props.filterOptions.map((status, index) => {
              return (
                <MenuItem
                  key={index} value={status.value}
                >
                  <Box sx={{textAlign: 'center'}}>
                    {status.value}
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </>
      }
      endAdornment={
        <>
          <IconButton size='small' disabled={selectedValue === ''} onClick={() => handleInputValueChange('')}>
            <Clear fontSize='small' />
          </IconButton>
        </>
      }
      onChange={(e) => handleInputValueChange(e.target.value)} />
  );
}