import React, {useState} from 'react'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {FeedbackPeriod} from "../../../../../types/FeedbackPeriod";
import {PeriodRowContainer} from "./PeriodRowContainer";
import {Loading} from "../../../../../components/Loading";
import {SortableTableHeadCell} from "../../../components/SortableTableHeadCell";
import {SortOrderTypes} from "../../../../../types/DatatableTypes";
import { CustomTableFooter } from '../../../../OrganiserPage/components/common/CustomTableFooter';

interface Props {
  periods: FeedbackPeriod[],
  isLoading: boolean,
  onRefreshData: () => void,
  onCheckConsistentPeriod: (start: Date, end: Date) => boolean,
  sortOrder: SortOrderTypes,
  onSort: () => void
}

export const PeriodManagementTable: React.FC<Props> = (
  {periods, isLoading, onRefreshData, onCheckConsistentPeriod, sortOrder, onSort}
) => {
  const [isEditing, setIsEditing] = useState(false);

  const captureEditState = (value: boolean) => {
    setIsEditing(value);
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {isEditing
              ? (<TableCell>Feedback Period(s)</TableCell>)
              : (<SortableTableHeadCell
                text="Feedback Period(s)"
                columnName="FeedbackPeriods"
                sortOrder={sortOrder}
                onSort={onSort}/>)
            }
            <TableCell align="right">
              <span style={{marginRight: '5px'}}>Status</span>
            </TableCell>
            <TableCell align="right">
              <span style={{marginRight: '12px'}}>Action</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(periods.length === 0 && !isLoading) &&
            <TableRow>
              <TableCell colSpan={8} align='center' sx={{color: '#aaa'}}>No data available</TableCell>
            </TableRow>
          }
          {
            periods.map((row, index) => (
              <PeriodRowContainer captureEditState={captureEditState} key={index} row={row} onRefreshData={onRefreshData} onCheckConsistentPeriod={onCheckConsistentPeriod}/>
            ))
          }
        </TableBody>
      </Table>
      { isLoading && <Loading />}
      <CustomTableFooter totalRows={periods.length} />
    </TableContainer>
  );
}
