import React, {useState} from 'react'
import {Box, MenuItem, Select} from "@mui/material";

interface Props {
  initValue: string,
  onchangeStatusNotes: (status: any) => void
}

export const STATUS_NOTES_LIST = [
  {key: 'SENT', value: "Sent"},
  {key: 'FOLLOW_UP_EMAIL_1', value: "Follow Up Email 1"},
  {key: 'FOLLOW_UP_EMAIL_2', value: "Follow Up Email 2"},
  {key: 'RECEIVED_EMAIL', value: "Received Email"}
];

export const StatusNotesDropdown: React.FC<Props> = ({initValue, onchangeStatusNotes}) => {
  const [selectedStatusNote, setSelectedStatusNote] = useState(initValue);

  const handleChangeStatusNote = (value: string) => {
    setSelectedStatusNote(value);
    onchangeStatusNotes(value);
  }

  return (
    <Select
      sx={{
        width: "200px",
        height: "35px"
      }}
      value={selectedStatusNote}
      onChange={(event) => handleChangeStatusNote(event.target.value)}
    >
      {STATUS_NOTES_LIST.map((status, index) => {
        return (
          <MenuItem
            key={index} value={status.value}
          >
            <Box sx={{textAlign: 'center'}}>
              {status.value}
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  )
}