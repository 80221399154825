import { NumberFilterOperator } from "../types/DatatableTypes";

export const filterDataListByNumber = (dataList: any[], filterProperty: string, operator: NumberFilterOperator, value: number) => {
  let list = dataList;
  switch (operator) {
    case NumberFilterOperator.Equals: {
      list = list.filter(x => x[filterProperty] === value);
      break;
    }
    case NumberFilterOperator.NotEqual: {
      list = list.filter(x => x[filterProperty] !== value);
      break;
    }
    case NumberFilterOperator.LessThan: {
      list = list.filter(x => x[filterProperty] < value);
      break;
    }
    case NumberFilterOperator.LessThanOrEquals: {
      list = list.filter(x => x[filterProperty] <= value);
      break;
    }
    case NumberFilterOperator.GreaterThan: {
      list = list.filter(x => x[filterProperty] > value);
      break;
    }
    case NumberFilterOperator.GreaterThanOrEquals: {
      list = list.filter(x => x[filterProperty] >= value);
      break;
    }
  }

  return list;
}