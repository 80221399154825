import * as React from 'react';
import Box from '@mui/material/Box';
import {Button, Stack, TextField, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {toDate, UI_DATE_PATTERN} from "../../../../../commons/dates";
import {PeriodStatusDropdown} from "../StatusDropdown";
import {WarningTooltip} from "../../../../../components/Tooltip";

interface Props {
  newStartDate: string,
  handleStartDateChange: (value: Date | null) => void,
  newEndDate: string,
  handleEndDateChange: (value: Date | null) => void,
  isActive: boolean,
  handleStatusChange: (status: boolean) => void,
  onCloseDialog: () => void,
  handleAddNewPeriod: () => void,
  canAdd: boolean,
  disabled: boolean
}

export const NewPeriodDialogMobileView: React.FC<Props> = (
  {newStartDate, handleStartDateChange, newEndDate, handleEndDateChange, isActive, handleStatusChange, onCloseDialog, handleAddNewPeriod, canAdd, disabled}
) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={1}>
        <Typography sx={{width: "85px", fontWeight: "bold", marginTop: "5px"}}>
          Date range:
        </Typography>
        <DatePicker
          label="Start Date"
          value={toDate(newStartDate)}
          onChange={(value) => handleStartDateChange(value)}
          renderInput={(props) =>
            <TextField onKeyDown={(e) => e.preventDefault()}  {...props} size='small' />}
          inputFormat={UI_DATE_PATTERN}
        />
        <DatePicker
          label="End Date"
          value={toDate(newEndDate)}
          onChange={(value) => handleEndDateChange(value)}
          renderInput={(props) =>
            <TextField onKeyDown={(e) => e.preventDefault()}  {...props} size='small' />}
          inputFormat={UI_DATE_PATTERN}
        />
        <Typography sx={{width: "85px", fontWeight: "bold", marginTop: "5px"}}>
          Status:
        </Typography>
        <PeriodStatusDropdown initStatus={isActive} onChangeStatus={handleStatusChange}/>
        {!canAdd && (<WarningTooltip
          placement={"right"}
          title={"The start date should be greater than now and less than end date."}/>)}
        <Box/>
        <Button
          variant="contained"
          onClick={onCloseDialog}
        >
          <strong>Cancel</strong>
        </Button>
        <Button
          disabled={!canAdd || disabled}
          variant="contained"
          onClick={handleAddNewPeriod}
        >
          <strong>Add</strong>
        </Button>
      </Stack>
    </Box>
  );
}