import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, } from "@mui/material";
import { FeedbackRequest, FeedbackRequestType, SubmitFeedbackRequest } from "../../types/FeedbackRequest";
import { FeedbackPeriodDropdown } from "../FeedbackPeriodDropdown";
import { PeerFeedbackRequestContainer } from "./components/PeerFeedbackRequest";
import { ClientFeedbackRequestContainer } from "./components/ClientFeedbackRequest";
import { addFeedbackRequestsByAdmin, retrieveFeedbackRequests, submitFeedbackRequests } from "../../services/FeedbackRequestService";
import { DuplicateFeedbackItem } from "../../types/DuplicateFeedbackItem";
import { isValidEmail } from "../../utils/ValidationHelpers";
import { checkSubmitStatus } from "../../services/StaffService";
import { enqueueSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import {LoadingOverlay} from "../Loading/LoadingOverlay";

const row = {
  marginBottom: '25px'
}

interface FeedbackRequestBoxProps {
  staffId: number,
  staffName: string,
  // properties for admin adding more requests
  isInAddDialog?: boolean,
  fixedPeriodId?: number,
  onCancel?: () => void,
  onSubmitted?: () => void
}

export const FeedbackRequestBox: React.FC<FeedbackRequestBoxProps> = (props: FeedbackRequestBoxProps) => {
  const [peerRequests, setPeerRequests] = useState<FeedbackRequest[]>([]);
  const [clientRequests, setClientRequests] = useState<FeedbackRequest[]>([]);

  const [feedbackPeriodId, setFeedbackPeriodId] = useState<number | undefined>(props.fixedPeriodId);

  const [isValidSubmit, setIsValidSubmit] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [peerReqDuplicate, setPeerReqDuplicate] = useState<any>({});
  const [clientRequestDuplicate, setClientReqDuplicate] = useState<any>({});
  const [canSubmit, setCanSubmit] = useState(true);
  const confirm = useConfirm();

  useEffect(() => {
    if (feedbackPeriodId) {
      checkSubmitStatus({ periodId: feedbackPeriodId })
        .then((res) => setCanSubmit(res.canSubmit))
      loadData();
    }
    // eslint-disable-next-line
  }, [feedbackPeriodId]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setIsValidSubmit(isPeriodAvailableSubmit() && isValidPeriodId() && isValidRequests());
    }, 300);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [feedbackPeriodId, peerRequests, clientRequests]);

  const isPeriodAvailableSubmit = () => {
    return props.isInAddDialog || canSubmit;
  }

  const loadData = () => {
    if (props.isInAddDialog) {
      return;
    }

    retrieveFeedbackRequests(feedbackPeriodId!)
      .then((res) => {
        // append existing requests to show
        if (res.length === 0) {
          setReadOnly(false);
          setPeerRequests([]);
          setClientRequests([]);
        } else {
          setReadOnly(true);
          const existedPeerRequests = res.filter(i => i.requestType === FeedbackRequestType.Peer)
            .map((i) => {
              return {
                peerStaffId: i.peerStaffId,
                clientCompany: i.clientCompany,
                projectName: i.projectName,
                requestType: FeedbackRequestType.Peer
              };
            });
          const existedClientRequests = res.filter(i => i.requestType === FeedbackRequestType.Client)
            .map((i) => {
              return {
                clientName: i.clientName,
                clientEmail: i.clientEmail,
                clientCompany: i.clientCompany,
                projectName: i.projectName,
                requestType: FeedbackRequestType.Client
              };
            });
          setPeerRequests(existedPeerRequests);
          setClientRequests(existedClientRequests)
        }
      })
      .catch((err) => {
        // do nothing
      })
  }

  const applyChangePeriod = (periodId?: number) => {
    setFeedbackPeriodId(periodId);
  }

  const addNewPeerRequest = () => {
    if (!feedbackPeriodId) {
      enqueueSnackbar('Please select one period before adding request.', { variant: 'warning' });
      return;
    }
    const newPeerRequest: FeedbackRequest = {
      peerStaffId: -1,
      clientName: '',
      clientCompany: '',
      projectName: '',
      requestType: FeedbackRequestType.Peer
    };
    setPeerRequests([...peerRequests, newPeerRequest]);
  }

  const applyPeerRequest = (index: number, event: any) => {
    const { name, value } = event.target;
    const data = [...peerRequests];
    // @ts-ignore
    data[index][name] = value;
    setPeerRequests(data);
  }

  const removePeerRequest = (index: number) => {
    const dataRow = [...peerRequests];
    dataRow.splice(index, 1);
    setPeerRequests(dataRow);
  }

  const addNewClientRequest = () => {
    if (!feedbackPeriodId) {
      enqueueSnackbar('Please select one period before adding request.', { variant: 'warning' });
      return;
    }
    const newClientRequest: FeedbackRequest = {
      clientName: '',
      clientEmail: '',
      clientCompany: '',
      projectName: '',
      requestType: FeedbackRequestType.Client
    };
    setClientRequests([...clientRequests, newClientRequest]);
  }

  const applyClientRequest = (index: number, event: any) => {
    const { name, value } = event.target;
    const data = [...clientRequests];
    // @ts-ignore
    data[index][name] = value;
    setClientRequests(data);
  }

  const removeClientRequest = (index: number) => {
    const dataRow = [...clientRequests];
    dataRow.splice(index, 1);
    setClientRequests(dataRow);
  }

  const handleSubmit = () => {
    const message = (
      <> 
        {!props.isInAddDialog && <>You are only able to submit once for this period.
          If you need further changes, please consult the Talent Team. <br /></>}
        Are you sure you want to submit feedback requests?
      </>
    );

    confirm({ title: 'Submit feedback requests?', description: message })
    .then(proceedSubmit);
  }

  const proceedSubmit = () => {
    setIsSubmitting(true);
    const feedbackRequests: FeedbackRequest[] = [...peerRequests, ...clientRequests];
    const postData: SubmitFeedbackRequest = { periodId: feedbackPeriodId!, requests: feedbackRequests };

    const promise = props.isInAddDialog ?
      addFeedbackRequestsByAdmin(props.staffId!, postData) :
      submitFeedbackRequests(postData);

    promise.then((res) => {
      enqueueSnackbar('Your requests have been submitted successfully', { variant: 'success' });
      props.onSubmitted && props.onSubmitted();
    })
      .finally(() => {
        setIsValidSubmit(false);
        setIsSubmitting(false);
        loadData();
      });
  }

  const isValidPeriodId = () => {
    return !!feedbackPeriodId;
  }

  const isValidRequests = () => {
    let res = true;
    let peerReqDuplicates: DuplicateFeedbackItem[] = [];
    let clientReqDuplicates: DuplicateFeedbackItem[] = [];
    if (peerRequests.length > 0 && clientRequests.length > 0) {
      // check both
      peerRequests.forEach((peerReq, index) => {
        peerReqDuplicates = peerReqDuplicates.concat(checkDuplicates(peerReq, index, peerRequests));
        if (invalidPeerRequestItem(peerReq)) {
          res = false;
          return;
        }
      });
      clientRequests.forEach((clientReq, index) => {
        clientReqDuplicates = clientReqDuplicates.concat(checkDuplicates(clientReq, index, clientRequests));
        if (invalidClientRequestItem(clientReq)) {
          res = false;
          return
        }
      })
    } else if (peerRequests.length > 0) {
      peerRequests.forEach((peerReq, index) => {
        peerReqDuplicates = peerReqDuplicates.concat(checkDuplicates(peerReq, index, peerRequests));
        if (invalidPeerRequestItem(peerReq)) {
          res = false;
          return;
        }
      });
    } else if (clientRequests.length > 0) {
      clientRequests.forEach((clientReq, index) => {
        clientReqDuplicates = clientReqDuplicates.concat(checkDuplicates(clientReq, index, clientRequests));
        if (invalidClientRequestItem(clientReq)) {
          res = false;
          return
        }
      })
    } else {
      // 2 list empty
      res = false;
    }
    setPeerReqDuplicate(groupBy(peerReqDuplicates, 'checkedIndex', 'lineFound'));
    setClientReqDuplicate(groupBy(clientReqDuplicates, 'checkedIndex', 'lineFound'));
    return res && peerReqDuplicates.length === 0 && clientReqDuplicates.length === 0;
  }

  const invalidPeerRequestItem = (peerReq: FeedbackRequest) => {
    return peerReq['peerStaffId'] === -1
      || peerReq['clientCompany'] === ''
      || peerReq['projectName'] === '';
  }

  const invalidClientRequestItem = (clientReq: FeedbackRequest) => {
    return clientReq['clientName'] === ''
      || clientReq['clientEmail'] === ''
      || !isValidEmail(clientReq['clientEmail'] || '')
      || clientReq['clientCompany'] === ''
      || clientReq['projectName'] === ''
  }

  const checkDuplicates =
    (checkedItem: FeedbackRequest, checkedIdx: number, items: FeedbackRequest[]) => {
      let res: DuplicateFeedbackItem[] = [];
      const cloneArr = [...items];
      cloneArr.splice(checkedIdx, 1);
      for (let i = 0; i < cloneArr.length; i++) {
        if (isSame(checkedItem, cloneArr[i])) {
          res = [...res, { checkedIndex: checkedIdx, lineFound: items.indexOf(cloneArr[i]) + 1 }];
        }
      }
      return res;
    }

  const isSame = (sourceItem: FeedbackRequest, targetItem: FeedbackRequest) => {
    return sourceItem && targetItem
      && sourceItem.peerStaffId === targetItem.peerStaffId
      && sourceItem.clientName?.trim().toLowerCase() === targetItem.clientName?.trim().toLowerCase()
      && sourceItem.clientEmail?.trim().toLowerCase() === targetItem.clientEmail?.trim().toLowerCase()
      && sourceItem.clientCompany.trim().toLowerCase() === targetItem.clientCompany.trim().toLowerCase()
      && sourceItem.projectName.trim().toLowerCase() === targetItem.projectName.trim().toLowerCase();
  }

  const groupBy: any = (array: any[], prop: any, target: any) => {
    return array.reduce((grouped, cur) => {
      if (!grouped[cur[prop]]) grouped[cur[prop]] = [];
      grouped[cur[prop]].push(cur[target]);
      return grouped;
    }, {})
  };

  const handleCancel = () => {
    if (peerRequests.length > 0 || clientRequests.length > 0) {
      const message = (
        <>
          If you close this dialog, you will lose all the changes you have made. <br />
          Are you sure you want to close?
        </>
      );
      confirm({title: 'Cancel' , description: message})
      .then(props.onCancel!);
    }
    else {
      props.onCancel && props.onCancel();
    }
  };

  return (
    <>
      {isSubmitting && <LoadingOverlay/>}
      <Grid container test-id={'feedback-container'}>
        <FeedbackPeriodDropdown
          onChange={applyChangePeriod}
          disabled={!!props.fixedPeriodId}
          defaultValue={props.fixedPeriodId}
        />

        <Grid container test-id={'feedback-for'} style={row}>
          <Grid item lg={1.5} sm={3} xs={12}>
            <Typography>
              Feedback For:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ fontWeight: "bold" }}>
              {props.staffName}
            </Typography>
          </Grid>
        </Grid>

        <PeerFeedbackRequestContainer
          items={peerRequests}
          add={addNewPeerRequest}
          apply={applyPeerRequest}
          remove={removePeerRequest}
          readonly={readOnly}
          duplicates={peerReqDuplicate}
          canSubmit={isPeriodAvailableSubmit()}
        />

        <ClientFeedbackRequestContainer
          items={clientRequests}
          add={addNewClientRequest}
          apply={applyClientRequest}
          remove={removeClientRequest}
          readonly={readOnly}
          duplicates={clientRequestDuplicate}
          canSubmit={isPeriodAvailableSubmit()}
        />
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
        {!!props.onCancel &&
          <Button
            style={{ width: '120px', marginRight: 10 }}
            variant="contained"
            onClick={handleCancel}>Cancel
          </Button>
        }

        <Button
          style={{ width: '120px' }}
          variant="contained"
          disabled={!isValidSubmit || readOnly}
          onClick={handleSubmit}>Submit
        </Button>
      </Box>
    </>
  );
};