import axios from "axios";
import {Staff} from "../types/Staff";
import {CheckSubmitStatusRequest, CheckSubmitStatusResponse} from "../types/CheckSubmitStatus";

export const retrieveStaffs = async (): Promise<Staff[]> => {
  return axios.get('/Staffs')
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const getStaffsLocations = async (): Promise<string[]> => {
  return axios.get('/Staffs/GetLocations')
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const checkSubmitStatus = async (body: CheckSubmitStatusRequest) : Promise<CheckSubmitStatusResponse> => {
  return axios.post('/Staffs/SubmitStatus', body)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    })
}