import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import { EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { useEffect } from 'react';
import { OrganiserPage } from "./pages/OrganiserPage";
import { FeedbackPeriodsPage } from './pages/Admin/FeedbackPeriodsPage';
import SignInPage from "./pages/SignInPage";
import { FeedbackRequestPage } from "./pages/FeedbackRequest";
import { Box } from "@mui/system";
import { CircularProgress, Typography } from "@mui/material";
import { useAppUser } from "./hooks/useAppUser";
import { AppUserModel, AppUserRole } from "./types/AppUserModel";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EmailTemplatePage from "./pages/Admin/EmailTemplatePage";
import { AdministratorsPage } from "./pages/Admin/AdministratorsPage";
import { PageNotFound } from "./pages/NotFoundPage";

function App() {
  const { instance, inProgress } = useMsal();
  const [isCheckingUser, setIsCheckingUser] = React.useState<boolean>(false);
  const { appUser, setAppUser } = useAppUser();

  useEffect(() => {
    const addUser = () => {
      setIsCheckingUser(true);
      axios
        .post("/auth/add-user")
        .then((response) => {
          const user: AppUserModel = { userId: response.data.userId, name: response.data.name, role: response.data.role, staffId: response.data.staffId };
          setAppUser(user);

          // ensure the app user gets stored in the previous line
          setTimeout(() => {
            setIsCheckingUser(false);
          }, 500);
        })
        .catch((error) => {
          setIsCheckingUser(false);
          localStorage.clear();
          if (error.response.status === 403) {
            alert("Your access is not allowed.");
          }
          else {
            alert(error.response?.data || error.message || "Un expected error occurred. Please try again later.")
          }
          window.location.reload();
        });
    };

    const callbackId = instance.addEventCallback((message: EventMessage) => {
      // Update UI or interact with EventMessage here
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        addUser();
      } else if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        localStorage.clear();
        window.location.reload();
      } else if (message.eventType === EventType.LOGIN_FAILURE) {
        console.log(message.error);
      }
    });

    return () => {
      callbackId && instance.removeEventCallback(callbackId);
    }
  }, [instance, setAppUser])

  if (inProgress === InteractionStatus.HandleRedirect || isCheckingUser) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '300px'
      }}>
        <CircularProgress />
        <Typography variant="subtitle1">
          Logging in...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <SignInPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
              <Routes>
                <Route path="/" element={<FeedbackRequestPage />} />
                {appUser && appUser.role === AppUserRole.Admin &&
                  <>
                    <Route path="/organiser/:active_tab?" element={<OrganiserPage />} />
                    <Route path="/admin/periods" element={<FeedbackPeriodsPage />} />
                    <Route path="/admin/email-template" element={<EmailTemplatePage />} />
                    <Route path="/admin/administrators" element={<AdministratorsPage />} />
                  </>
                }
                <Route path="*" element={<PageNotFound />} />
              </Routes>
          </Router>
        </LocalizationProvider>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;