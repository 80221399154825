import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom"
import { FeedbackPeriodDropdown } from "../../components/FeedbackPeriodDropdown";
import { LocationDropdown } from "../../components/LocationDropdown/LocationDropdown";
import { ClientSummaryTable } from "./components/ClientSummaryTable";
import { StaffAssignedTable } from "./components/StaffAssignedTable";
import { StaffRequestTable } from "./components/StaffRequestTable";
import { AppLayout } from "../AppLayout";

enum TabIndex {
  StaffRequest = 0,
  StaffAssigned = 1,
  ClientSummary = 2
}

const convertTabKeyToTabIndex = (tabKey: string): TabIndex => {
  if (tabKey?.toLowerCase() === 'staff-assigned') {
    return TabIndex.StaffAssigned;
  }
  else if (tabKey?.toLowerCase() === 'client-summary') {
    return TabIndex.ClientSummary;
  }
  else {
    return TabIndex.StaffRequest;
  }
}

export const OrganiserPage: React.FC = () => {
  const { active_tab } = useParams();

  const [feedbackPeriodId, setFeedbackPeriodId] = React.useState<number | undefined>(undefined);
  const [location, setLocation] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState<TabIndex>(convertTabKeyToTabIndex(active_tab as string));

  const applyChangePeriod = (periodId?: number) => {
    setFeedbackPeriodId(periodId);
  }

  const handleChangeTab = (_: React.SyntheticEvent, newValue: TabIndex) => {
    setSelectedTab(newValue);
  };

  return (
    <AppLayout>
      <div>
        <FeedbackPeriodDropdown onChange={applyChangePeriod} />
      </div>
      <div style={{ visibility: (selectedTab === TabIndex.ClientSummary ? 'hidden' : 'visible') }}>
        <LocationDropdown onChange={setLocation} />
      </div>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleChangeTab} TabIndicatorProps={{ sx: { backgroundColor: "primary.main" } }}>
            <Tab label="Staff Request" component={Link} to={'/organiser'} />
            <Tab label="Staff Assigned" component={Link} to={'/organiser/staff-assigned'} />
            <Tab label="Client Summary" component={Link} to={'/organiser/client-summary'} />
          </Tabs>
        </Box>
        {feedbackPeriodId &&
          <>
            <TabPanel value={selectedTab} index={TabIndex.StaffRequest}>
              <StaffRequestTable periodId={feedbackPeriodId} location={location} />
            </TabPanel>
            <TabPanel value={selectedTab} index={TabIndex.StaffAssigned}>
              <StaffAssignedTable periodId={feedbackPeriodId} location={location} />
            </TabPanel>
            <TabPanel value={selectedTab} index={TabIndex.ClientSummary}>
              <ClientSummaryTable periodId={feedbackPeriodId} />
            </TabPanel>
          </>
        }
      </Box>
    </AppLayout>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: TabIndex;
  value: TabIndex;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
