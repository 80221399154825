export enum FeedbackRequestType {
  None = 0,
  Peer = 1,
  Client = 2
}

export interface FeedbackRequest {
  id?: number;
  peerStaffId?: number;
  peerStaffFullName?: string;
  requestorStaffFullName?: string;
  clientName?: string;
  clientEmail?: string;
  clientCompany: string;
  projectName: string;
  requestType: FeedbackRequestType
}

export interface FeedbackRequests {
  feedbackRequests: FeedbackRequest[];
}

export const EMPTY: FeedbackRequests = {
  feedbackRequests: [],
}

export interface SubmitFeedbackRequest {
  periodId: number;
  requests: FeedbackRequest[];
}

export interface SubmitFeedbackResponse {
  feedbackPeriodId: number;
  requestorStaffId: number;
  peerStaffId: number;
  clientName: string;
  clientEmail: string;
  clientCompany: string;
  projectName: string;
  requestType: number;
  created: string;
  createdBy: string;
  lastModified: string;
  lastModifiedBy: string;
  id: number;
}

export interface PeerFeedbackUpdateModel {
  peerStaffId: number;
  clientCompany: string;
  projectName: string;
}

export interface ClientFeedbackUpdateModel {
  clientName: string;
  clientEmail: string;
  clientCompany: string;
  projectName: string;
}

export interface ClientSummaryUpdateModel {
  originalClientEmail: string,
  periodId: number;
  clientName: string;
  clientEmail: string;
  company: string;
  statusNote: string;
}