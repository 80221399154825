import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import {toPeriodString} from "../../../../../commons/dates";
import {StatusToggle} from "../StatusToggle";
import React from "react";
import {FeedbackPeriod} from "../../../../../types/FeedbackPeriod";
import {deleteFeedbackPeriod} from "../../../../../services/FeedbackPeriodService";
import {enqueueSnackbar} from "notistack";
import { useConfirm } from "material-ui-confirm";

interface Props {
  row: FeedbackPeriod,
  onEditMode: () => void,
  onRefreshData: () => void,
}

export const PeriodViewModeRow: React.FC<Props> = ({row, onEditMode, onRefreshData}) => {
  const confirm = useConfirm();

  const handleDeletePeriod = () => {
    confirm({ title: 'Delete period', description: "Are you sure you want to delete this period?" })
    .then(() => {
      deleteFeedbackPeriod(row.id)
      .then((res) => {
        enqueueSnackbar('The period has been deleted successfully', { variant: 'success'});
        onRefreshData();
      });
    });
  }

  return (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        <span style={{marginLeft: '10px'}}>{toPeriodString(row.startDate, row.endDate)}</span>
      </TableCell>
      <TableCell align="right">
        <StatusToggle toggled={row.isActive}/>
      </TableCell>
      <TableCell align="right">
        <Button size="small" onClick={() => onEditMode() }>Edit</Button>
        <Button size="small" onClick={handleDeletePeriod}>Delete</Button>
      </TableCell>
    </TableRow>
  );
}