import React from "react";
import { Box, CircularProgress} from "@mui/material";
import theme from "../../theme";

export const LoadingOverlay: React.FC = () => {

  return (
    <Box
      sx={{
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backdropFilter: 'blur(2px)'
      }}
    >
      <CircularProgress sx={{ m: 2 }} />
    </Box>
  );
}