import React, {useCallback, useEffect, useState} from "react";
import {PeriodManagementTable} from "./components/PeriodManagementTable";
import {Box} from "@mui/material";
import {NewPeriodDialog} from "./components/NewPeriodDialog";
import {FeedbackPeriod} from "../../../types/FeedbackPeriod";
import {retrieveFeedbackPeriods} from "../../../services/FeedbackPeriodService";
import {toDate} from "../../../commons/dates";
import {SortOrderTypes} from "../../../types/DatatableTypes";
import useMediaQuery from "@mui/material/useMediaQuery";
import {ResponsiveAddButton} from "../components/ResponsiveAddButton";
import {AppLayout} from "../../AppLayout";

interface Props {}

export const FeedbackPeriodsPage: React.FC<Props> = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [periods, setPeriods] = useState<FeedbackPeriod[]>([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = React.useState<SortOrderTypes>('desc');
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const loadData = useCallback((force: boolean) => {
    if (force || (periods.length === 0 && count === 0)) {
      setSortOrder('desc');
      setIsLoading(true);
      retrieveFeedbackPeriods(false)
        .then((res) => {
          const sortedPeriods = res.sort((a, b) =>
            // sort desc
            toDate(b.endDate).setHours(0, 0, 0, 0) -
            toDate(a.endDate).setHours(0, 0, 0, 0)
          );
          setPeriods(sortedPeriods);
          setIsLoading(false);
          setCount(1);
        });
    }
  }, [periods, count]);

  const onSort = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  }

  const effectSort = useCallback( () => {
    const sortedPeriods = [];
    if (sortOrder === "asc") {
      sortedPeriods.push(...periods.sort((a, b) =>
        toDate(a.endDate).setHours(0, 0, 0, 0) -
        toDate(b.endDate).setHours(0, 0, 0, 0)));
    } else {
      sortedPeriods.push(...periods.sort((a, b) =>
        toDate(b.endDate).setHours(0, 0, 0, 0) -
        toDate(a.endDate).setHours(0, 0, 0, 0)));
    }
    setPeriods(sortedPeriods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder]);

  useEffect(() =>{
    loadData(false);
  },[loadData]);

  useEffect(() => {
    effectSort();
  },[effectSort]);

  const checkConsistentPeriod = (startDate: Date, endDate: Date) => {
    return startDate.setHours(0,0,0,0) < endDate.setHours(0,0,0,0);
  }

  return (
    <AppLayout>
      <Box>
        <ResponsiveAddButton isSmallScreen={isSmallScreen} onClickAction={setOpenDialog}/>
        {openDialog &&
          <NewPeriodDialog
            isOpenDialog={openDialog}
            onCloseDialog={() => setOpenDialog(false)}
            onCheckConsistentPeriod={checkConsistentPeriod}
            onRefreshData={() => loadData(true)}
            isSmallScreen={isSmallScreen}
          />
        }
        <PeriodManagementTable
          periods={periods}
          isLoading={isLoading}
          onCheckConsistentPeriod={checkConsistentPeriod}
          onRefreshData={() => loadData(true)}
          sortOrder={sortOrder}
          onSort={onSort}
        />
      </Box>
    </AppLayout>
  );
};
