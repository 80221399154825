import React from "react";
import { Box, CircularProgress} from "@mui/material";

export const Loading: React.FC = () => {

  return (
    <Box
      sx={{
        color: '#fff',
        zIndex: 1000,
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
      }}
    >
      <CircularProgress sx={{ m: 2 }} />
    </Box>
  );
}