import { Grid, Typography } from "@mui/material";
import { ClientFeedbackRequestDesktopView } from "./ClientFeedbackRequestDesktopView";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ClientFeedbackRequestMobileView } from "./ClientFeedbackRequestMobileView";
import { FeedbackRequest } from "../../../../types/FeedbackRequest";
import { GenericTooltip } from "../../../Tooltip";

interface Props {
  items: FeedbackRequest[],
  add: () => void,
  apply: (index: number, event: any) => void,
  remove: (index: number) => void,
  readonly: boolean,
  duplicates: any,
  canSubmit: boolean
}

const row = {
  marginBottom: '10px'
}

export const ClientFeedbackRequestContainer = (props: Props) => {
  const isSmallScreen = useMediaQuery('(max-width:500px)');

  return (
    <Grid aria-readonly={props.readonly} container test-id={'client-feedback'} style={row}>
      <Grid container test-id={'client-feedback-title'}>
        <Typography>
          I would like client feedback from:
        </Typography>
        <GenericTooltip
          title='Please select the client that you have worked most closely with during this period.'
          placement={"right"}
          icon={<HelpOutlineIcon style={{ cursor: 'pointer', marginLeft: 2, width: 18, color: '#777' }} />} />
        {props.readonly &&
          <Typography>
            Feedback request has already submitted for this period. Consult Talent team if further changes are needed.
          </Typography>
        }
      </Grid>
      <Grid container test-id={'client-feedback-content'} style={row}>
        {isSmallScreen ? <ClientFeedbackRequestMobileView {...props} /> : <ClientFeedbackRequestDesktopView {...props} />}
      </Grid>
    </Grid>
  );
}