import React from 'react'
import {Box, Switch} from "@mui/material";

interface Props {
  toggled: boolean,
}

export const StatusToggle: React.FC<Props> = ({ toggled}) => {
  return (
    <Box>
      <Switch
        sx={{pointerEvents: 'none'}}
        size="medium"
        checked={toggled}
      />
    </Box>
  )
}