import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getStaffAssignedSummaries } from '../../../../services/FeedbackRequestService';
import { Loading } from '../../../../components/Loading';
import { CustomTableRow } from './CustomTableRow';
import { NumberFilterOperator, SortOrderTypes } from '../../../../types/DatatableTypes';
import { StaffRequestSummaryModel } from '../../../../types/StaffRequestSummaryModel';
import { CustomTableHeadCell } from '../common/CustomTableHeadCell';
import { TableCell } from '@mui/material';
import { NumberFilterInput, TextFilterInput } from '../common/FilterInputs';
import { filterDataListByNumber } from '../../../../utils/FilterHelper';
import { CustomTableFooter } from '../common/CustomTableFooter';

type StaffAssignedTableProps = {
  periodId: number,
  location: string
};

export function StaffAssignedTable(props: StaffAssignedTableProps) {
  const [staffs, setStaffs] = React.useState<StaffRequestSummaryModel[]>([]);
  const [sortBy, setSortBy] = React.useState<string>('StaffName');
  const [sortOrder, setSortOrder] = React.useState<SortOrderTypes>('asc');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [staffNameFilter, setStaffNameFilter] = React.useState('');
  const [totalRequestOperator, setTotalRequestOperator] = React.useState<NumberFilterOperator>(NumberFilterOperator.Equals);
  const [totalRequestFilter, setTotalRequestFilter] = React.useState<number | ''>('');
  const [filteredStaffs, setFilteredStaffs] = React.useState<StaffRequestSummaryModel[]>([]);

  const loadData = React.useCallback(() => {
    setIsLoading(true);
    getStaffAssignedSummaries(props.periodId, props.location, sortBy, sortOrder)
      .then((res) => {
        setStaffs(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setStaffs([]);
        setIsLoading(false);
      })
  }, [setStaffs, props, sortBy, sortOrder]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  React.useEffect(() => {
    let list = staffs;

    if (staffNameFilter.length > 0) {
      list = list.filter(x =>
        x.staffName.toLowerCase().indexOf(staffNameFilter.toLowerCase()) >= 0 ||
        x.preferredName?.toLowerCase().indexOf(staffNameFilter.toLowerCase()) >= 0);
    }

    if (totalRequestFilter !== '') {
      list = filterDataListByNumber(list, 'totalRequest', totalRequestOperator, totalRequestFilter);
    }

    setFilteredStaffs(list);
  }, [staffNameFilter, setFilteredStaffs, totalRequestOperator, totalRequestFilter, staffs]);

  const handleSort = (newSortBy: string) => {
    let newSortOrder: SortOrderTypes = 'asc';
    if (newSortBy === sortBy) {
      newSortOrder = sortOrder === '' ? 'asc' : (sortOrder === 'asc' ? 'desc' : '');
    }

    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  }

  const handleTotalRequestFilterChange = (value: number | '', operator: NumberFilterOperator) => {
    setTotalRequestFilter(value);
    setTotalRequestOperator(operator);
  }

  return (
    <TableContainer component={Paper} sx={{ position: 'relative' }}>
      {isLoading && staffs.length > 0 &&
        <Box sx={{ position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.6)', width: '100%', height: '100%' }}>
          <Loading />
        </Box>
      }
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <CustomTableHeadCell sx={{ width: 20 }} />
            <CustomTableHeadCell text='Staff' columnName='StaffName' sortOrder={sortBy === 'StaffName' ? sortOrder : ''} onSort={handleSort} />
            <CustomTableHeadCell text='Total Feedbacks' columnName='TotalRequest' sortOrder={sortBy === 'TotalRequest' ? sortOrder : ''} onSort={handleSort} align='right' />
          </TableRow>
        </TableHead>
        <TableBody>
          {/* BEGIN: Filter row */}
          {staffs.length > 0 &&
            < TableRow >
              <TableCell />
              <TableCell sx={{ px: 1 }} >
                <TextFilterInput onChange={(value: string) => setStaffNameFilter(value)} />
              </TableCell>
              <TableCell sx={{ px: 1 }}>
                <NumberFilterInput onChange={handleTotalRequestFilterChange} />
              </TableCell>
            </TableRow>
          }
          {/* END: Filter row */}
          {isLoading && staffs.length === 0 &&
            <TableRow>
              <TableCell colSpan={4}>
                <Loading />
              </TableCell>
            </TableRow>
          }
          {filteredStaffs.map((staff) => (
            <CustomTableRow key={staff.staffId} periodId={props.periodId} staff={staff} onDeleted={() => loadData()} />
          ))
          }
        </TableBody>
      </Table>
      <CustomTableFooter totalRows={filteredStaffs.length} />
    </TableContainer >
  );
}
