import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";

interface Props {
}

export const Copyright: React.FC<Props> = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        CodeHQ
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
