import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  API_DATE_TIME_PATTERN,
  formatDate,
  isSameDates,
  toDate,
  UI_DATE_PATTERN
} from "../../../../../commons/dates";
import React, {useCallback, useEffect, useState} from "react";
import {FeedbackPeriod} from "../../../../../types/FeedbackPeriod";
import {Box, Button, TextField} from "@mui/material";
import {PeriodStatusDropdown} from "../StatusDropdown";
import {DatePicker} from "@mui/x-date-pickers";
import {isEqual} from "date-fns";
import {WarningTooltip} from "../../../../../components/Tooltip";
import {updateFeedbackPeriod} from "../../../../../services/FeedbackPeriodService";
import {enqueueSnackbar} from "notistack";
import {ConfirmCancelMessage} from "../../../../OrganiserPage/components/common/ConfirmCancelMessage";
import {useConfirm} from "material-ui-confirm";

interface Props {
  row: FeedbackPeriod,
  onCancel: () => void,
  onCheckConsistentPeriod: (start: Date, end: Date) => boolean,
  onViewMode: () => void
  onRefreshData: () => void,
}

export const PeriodEditModeRow: React.FC<Props> = (
  {row, onCancel, onCheckConsistentPeriod, onViewMode, onRefreshData}
) => {
  const confirm = useConfirm();
  const [periodUpdating, setPeriodUpdating] = useState<FeedbackPeriod>(
    {id: row.id, startDate: row.startDate, endDate: row.endDate, isActive: row.isActive}
  );
  const [disabled, setDisabled] = useState(false);

  const [canUpdate, setCanUpdate] = useState(
    onCheckConsistentPeriod(toDate(periodUpdating.startDate), toDate(periodUpdating.endDate))
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setCanUpdate(
        onCheckConsistentPeriod(toDate(periodUpdating.startDate), toDate(periodUpdating.endDate))
      );
    }, 200);
    return () => clearTimeout(timeOutId);
  }, [onCheckConsistentPeriod, periodUpdating]);

  const isDataRowChange = () => {
    return !(isSameDates(row.startDate, periodUpdating.startDate)
      && isSameDates(row.endDate, periodUpdating.endDate)
      && row.isActive === periodUpdating.isActive);
  }

  const handleStartDateChange = useCallback(
    (newDate: Date | null) => {
      if (!newDate || isEqual(newDate, toDate(periodUpdating.startDate))) {
        return;
      }
      setPeriodUpdating((prev) => {
        return {...prev, startDate: formatDate(newDate, API_DATE_TIME_PATTERN)}
      });
    },
    [periodUpdating.startDate],
  );

  const handleEndDateChange = useCallback(
    (newDate: Date | null) => {
      if (!newDate || isEqual(newDate, toDate(periodUpdating.endDate))) {
        return;
      }
      setPeriodUpdating((prev) => {
        return {...prev, endDate: formatDate(newDate, API_DATE_TIME_PATTERN)}
      });
    },
    [periodUpdating.endDate],
  );

  const handleStatusChange = useCallback(
    (status: boolean) => {
      setPeriodUpdating((prev) => {
        return {...prev, isActive: status}
      });
    },
    [],
  );

  const handleEditPeriod = () => {
    setDisabled(true);
    if (isDataRowChange()) {
      updateFeedbackPeriod(
        {periodId: periodUpdating.id, startDate: periodUpdating.startDate, endDate: periodUpdating.endDate, isActive: periodUpdating.isActive}
      )
        .then(() => {
          enqueueSnackbar('The period has been updated successfully', { variant: 'success'});
          onViewMode();
          onRefreshData();
        })
        .catch(() => setDisabled(false));
    }
  }

  const onCancelWithConfirm = () => {
    const isNotEdited = periodUpdating.startDate === row.startDate
      && periodUpdating.endDate === row.endDate
      && periodUpdating.isActive === row.isActive;
    if (isNotEdited) {
      onCancel();
    } else {
      confirm({ title: 'Cancel', description: ConfirmCancelMessage })
        .then(() => onCancel());
    }
  }

  return (
    <TableRow key={periodUpdating.id}>
      <TableCell component="th" scope="row">
        <Box sx={{
          display: "flex",
          gap: "30px",
          m: 2
        }}>
          <DatePicker
            label="Start Date"
            value={toDate(periodUpdating.startDate)}
            onChange={(value) => handleStartDateChange(value)}
            renderInput={(props) =>
              <TextField onKeyDown={(e) => e.preventDefault()}  {...props} size='small' />}
            inputFormat={UI_DATE_PATTERN}
          />
          <DatePicker
            label="End Date"
            value={toDate(periodUpdating.endDate)}
            onChange={(value) => handleEndDateChange(value)}
            renderInput={(props) =>
              <TextField onKeyDown={(e) => e.preventDefault()}  {...props} size='small' />}
            inputFormat={UI_DATE_PATTERN}
          />
          {!canUpdate && (<WarningTooltip
            title={"The updated start date should be greater than now and less than end date."}/>)}
        </Box>
      </TableCell>
      <TableCell align="right">
        <PeriodStatusDropdown initStatus={periodUpdating.isActive} onChangeStatus={handleStatusChange}/>
      </TableCell>
      <TableCell align="right">
        <Button
          disabled={!(isDataRowChange() && canUpdate) || disabled}
          size="small" onClick={handleEditPeriod}>
          Save
        </Button>
        <Button size="small" onClick={onCancelWithConfirm}>Cancel</Button>
      </TableCell>
    </TableRow>
  );
}