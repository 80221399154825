import React, {useMemo, useState} from "react";
import {
  Button, InputAdornment, ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Typography
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { RemoveCircleOutline } from "@mui/icons-material";
import { FeedbackRequest } from "../../../../types/FeedbackRequest";
import { Staff } from "../../../../types/Staff";
import { WarningTooltip } from "../../../Tooltip";
import { InputContainerBox } from "../../../InputContainerBox";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  items: FeedbackRequest[],
  peers: Staff[],
  add: () => void,
  apply: (index: number, event: any) => void,
  remove: (index: number) => void,
  readonly: boolean,
  duplicates: any,
  canSubmit: boolean
}

const dropdown = {
  width: '90%'
}

const outlineInputPops = {
  maxLength: 100
};

const tableHeader = {
  width: 300
}

export const PeerFeedbackRequestDesktopView: React.FC<Props> = (
  { items, peers, add, apply, remove, readonly, duplicates, canSubmit }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const filteredPeers = useMemo(
    () => peers.filter((peer) => {
      const name = peer.firstName + ' ' + peer.lastName + (peer.preferredName ? ` (${peer.preferredName})` : '');
      return containsText(name, searchTerm);
    }), [searchTerm, peers]);

  const renderValue = (selectedId: number | undefined) => {
    if (!selectedId || selectedId === -1) {
      return '--- Select a peer ---';
    }
    const peer =  peers.find((peer) => peer.id === selectedId);
    return peer
      ? peer.firstName + ' ' + peer.lastName + (peer.preferredName ? ` (${peer.preferredName})` : '')
      : '';
  }

  return (
    <TableContainer component={Paper} style={{ marginTop: 15 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={tableHeader} align="left">First and Last Name</TableCell>
            <TableCell style={tableHeader} align="left">for work related to</TableCell>
            <TableCell style={tableHeader} align="left">Company</TableCell>
            <TableCell style={tableHeader} align="left">Project or Work Title</TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((data, index) => {
              const { peerStaffId, clientCompany, projectName } = data;
              return (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={index}
                >
                  <TableCell component="th" scope="row">
                    <Select
                      disabled={readonly}
                      style={dropdown}
                      size='small'
                      name="peerStaffId"
                      value={peerStaffId}
                      onChange={(event) => apply(index, event)}
                      onClose={() => setSearchTerm("")}
                      renderValue={() => renderValue(peerStaffId)}
                      MenuProps={{
                        autoFocus: false,
                        PaperProps: {
                          style: { height: '200px', overflowY: 'scroll' }
                        }
                      }}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                              setSearchTerm('');
                            }
                          }}
                        />
                      </ListSubheader>
                      <MenuItem value={-1}>--- Select a peer ---</MenuItem>
                      {filteredPeers.map((peer, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={peer.id}
                          >
                            {peer.firstName + ' ' + peer.lastName + (peer.preferredName ? ` (${peer.preferredName})` : '')}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!peerStaffId && <WarningTooltip title={"Mandatory field."} />}
                  </TableCell>
                  <TableCell align="center" />
                  <TableCell align="left">
                    <InputContainerBox>
                      <OutlinedInput
                        inputProps={outlineInputPops}
                        disabled={readonly}
                        size='small'
                        autoComplete={'off'}
                        name="clientCompany"
                        value={clientCompany}
                        onChange={(event) => apply(index, event)}
                      />
                      {!clientCompany && <WarningTooltip title={"Mandatory field."} />}
                    </InputContainerBox>
                  </TableCell>
                  <TableCell align="left">
                    <InputContainerBox>
                      <OutlinedInput
                        inputProps={outlineInputPops}
                        disabled={readonly}
                        size='small'
                        autoComplete={'off'}
                        name="projectName"
                        value={projectName}
                        onChange={(event) => apply(index, event)}
                      />
                      {!projectName && <WarningTooltip title={"Mandatory field."} />}
                    </InputContainerBox>
                  </TableCell>
                  <TableCell align="left">
                    <Button disabled={readonly} onClick={() => remove(index)}>
                      <RemoveCircleOutline />
                    </Button>
                    {duplicates[index] && (<WarningTooltip title={"Duplicated entry at line: " + duplicates[index]} />)}
                  </TableCell>
                </TableRow>
              )
            })
          }
          {readonly && !items.length ?
            <TableRow>
              <TableCell colSpan={4}>
                <Typography sx={{ color: '#aaa' }}>No data available.</Typography>
              </TableCell>
            </TableRow>
            :
            (canSubmit &&
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {canSubmit && (
                    <Button disabled={readonly} onClick={add}>
                      <AddCircleOutlineIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};
