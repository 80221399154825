import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Loading } from '../../../../components/Loading';
import { CustomTableRow } from './CustomTableRow';
import { NumberFilterOperator, SortOrderTypes } from '../../../../types/DatatableTypes';
import { getClientSummaries } from '../../../../services/FeedbackRequestService';
import { ClientSummaryModel } from '../../../../types/ClientSummaryModel';
import { CustomTableHeadCell } from '../common/CustomTableHeadCell';
import { TableCell } from '@mui/material';
import EmailContentDialog from './EmailContentDialog';
import { filterDataListByNumber } from '../../../../utils/FilterHelper';
import {DropdownFilterInput, NumberFilterInput, TextFilterInput} from '../common/FilterInputs';
import { CustomTableFooter } from '../common/CustomTableFooter';
import {STATUS_NOTES_LIST} from "./StatusNotesDropdown";

type ClientSummaryTableProps = {
  periodId: number
};

export function ClientSummaryTable(props: ClientSummaryTableProps) {
  const [summaryList, setSummaryList] = React.useState<ClientSummaryModel[]>([]);
  const [sortBy, setSortBy] = React.useState<string>('StaffName');
  const [sortOrder, setSortOrder] = React.useState<SortOrderTypes>('asc');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isOpenEmailDialog, setIsOpenEmailDialog] = React.useState<boolean>(false);
  const [clientEmailForEmailDialog, setClientEmailForEmailDialog] = React.useState('');

  const [clientFilter, setClientFilter] = React.useState('');
  const [companyFilter, setCompanyFilter] = React.useState('');
  const [emailFilter, setEmailFilter] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState('');
  const [totalRequestOperator, setTotalRequestOperator] = React.useState<NumberFilterOperator>(NumberFilterOperator.Equals);
  const [totalRequestFilter, setTotalRequestFilter] = React.useState<number | ''>('');
  const [filteredDataList, setFilteredDataList] = React.useState<ClientSummaryModel[]>([]);

  const loadData = React.useCallback(() => {
    setIsLoading(true);
    getClientSummaries(props.periodId, sortBy, sortOrder)
      .then((res) => {
        setSummaryList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setSummaryList([]);
        setIsLoading(false);
      })
  }, [setSummaryList, props, sortBy, sortOrder]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  React.useEffect(() => {
    let list = summaryList;

    if (clientFilter.length > 0) {
      list = list.filter(x => x.clientName.toLowerCase().indexOf(clientFilter.toLowerCase()) >= 0);
    }

    if (companyFilter.length > 0) {
      list = list.filter(x => x.company.toLowerCase().indexOf(companyFilter.toLowerCase()) >= 0);
    }

    if (emailFilter.length > 0) {
      list = list.filter(x => x.clientEmail.toLowerCase().indexOf(emailFilter.toLowerCase()) >= 0);
    }

    if (statusFilter.length > 0) {
      list = list.filter(x => x.statusNote?.toLowerCase().indexOf(statusFilter.toLowerCase()) >= 0);
    }

    if (totalRequestFilter !== '') {
      list = filterDataListByNumber(summaryList, 'totalRequest', totalRequestOperator, totalRequestFilter);
    }

    setFilteredDataList(list);
  }, [clientFilter, companyFilter, emailFilter, statusFilter, setFilteredDataList, totalRequestOperator, totalRequestFilter, summaryList]);

  const handleSort = (newSortBy: string) => {
    let newSortOrder: SortOrderTypes = 'asc';
    if (newSortBy === sortBy) {
      newSortOrder = sortOrder === '' ? 'asc' : (sortOrder === 'asc' ? 'desc' : '');
    }

    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  }

  const handleGeneratedEmailClick = (clientEmail: string) => {
    setClientEmailForEmailDialog(clientEmail);
    setIsOpenEmailDialog(true);
  }

  const handleTotalRequestFilterChange = (value: number | '', operator: NumberFilterOperator) => {
    setTotalRequestFilter(value);
    setTotalRequestOperator(operator);
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        {isLoading && summaryList.length > 0 &&
          <Box sx={{ position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.6)', width: '100%', height: '100%' }}>
            <Loading />
          </Box>
        }
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <CustomTableHeadCell sx={{ width: 20 }} />
              <CustomTableHeadCell
                text='Client'
                columnName='ClientName'
                sortOrder={sortBy === 'ClientName' ? sortOrder : ''}
                onSort={handleSort} />
              <CustomTableHeadCell
                text='Company'
                columnName='Company'
                sortOrder={sortBy === 'Company' ? sortOrder : ''}
                onSort={handleSort} />
              <CustomTableHeadCell
                text='Email'
                columnName='ClientEmail'
                sortOrder={sortBy === 'ClientEmail' ? sortOrder : ''}
                onSort={handleSort} />
              <CustomTableHeadCell
                text='Total Feedbacks'
                columnName='TotalRequest'
                sortOrder={sortBy === 'TotalRequest' ? sortOrder : ''}
                onSort={handleSort} align='right'
                sx={{ whiteSpace: 'nowrap' }} />
              <CustomTableHeadCell
                text='Send Email' />
              <CustomTableHeadCell
                text='Status Note'
                columnName='StatusNote'
                sortOrder={sortBy === 'StatusNote' ? sortOrder : ''}
                onSort={handleSort} />
              <CustomTableHeadCell
                text='Action'
                align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* BEGIN: Filter row */}
            {summaryList.length > 0 &&
              <TableRow>
                <TableCell />
                <TableCell sx={{ px: 1 }} >
                  <TextFilterInput onChange={(value: string) => setClientFilter(value)} />
                </TableCell>
                <TableCell sx={{ px: 1 }} >
                  <TextFilterInput onChange={(value: string) => setCompanyFilter(value)} />
                </TableCell>
                <TableCell sx={{ px: 1 }} >
                  <TextFilterInput onChange={(value: string) => setEmailFilter(value)} />
                </TableCell>
                <TableCell sx={{ px: 1, maxWidth: 250 }}>
                  <NumberFilterInput onChange={handleTotalRequestFilterChange} />
                </TableCell>
                <TableCell />
                <TableCell sx={{ px: 1 }}>
                <DropdownFilterInput filterOptions={STATUS_NOTES_LIST} onChange={(value: string) => setStatusFilter(value)} />
                </TableCell>
                <TableCell />
              </TableRow>
            }
            {isLoading && summaryList.length === 0 ?
              <TableRow>
                <TableCell colSpan={8}>
                  <Loading />
                </TableCell>
              </TableRow>
              :
              (summaryList.length === 0 &&
                <TableRow>
                  <TableCell colSpan={8} align='center' sx={{ color: '#aaa' }}>No data available</TableCell>
                </TableRow>
              )
            }
            {/* END: Filter row */}
            {filteredDataList.map((item) => (
              <CustomTableRow
                key={item.clientEmail}
                periodId={props.periodId}
                summaryItem={item}
                onDeleted={loadData}
                onSaved={loadData}
                onGenerateEmailClick={() => handleGeneratedEmailClick(item.clientEmail)} />
            ))}
          </TableBody>
        </Table>
        <CustomTableFooter totalRows={filteredDataList.length} />
      </TableContainer>

      {isOpenEmailDialog &&
        <EmailContentDialog
          isOpen={isOpenEmailDialog}
          onClose={() => setIsOpenEmailDialog(false)}
          clientEmail={clientEmailForEmailDialog}
          periodId={props.periodId}
        />
      }
    </>
  );
}
