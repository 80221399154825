import {add, format, isEqual, parseISO} from "date-fns"

export const UI_DATE_PATTERN = 'dd MMM yyyy'
export const API_DATE_TIME_PATTERN = 'yyyy-MM-dd\'T\'HH:mm:ss';

export type DateTimeRange = {
  startDate: string;
  endDate: string;
}

export const formatDateString = (date: string): string => {
  return format(parseISO(date), UI_DATE_PATTERN);
};

export const formatDate = (date: Date, pattern: string) => {
  return format(date, pattern);
};

export const toPeriodString = (startDate: string, endDate: string): string => {
  return formatDateString(startDate) + ' - ' + formatDateString(endDate);
};

export const toDate = (dateString: string): Date => {
  return new Date(dateString);
}

export const today = () => new Date();

export const tomorrow = () => add(new Date(),{days: 1});

export const isSameDates = (sourceDate: string, targetDate: string) => {
  return isEqual(toDate(sourceDate), toDate(targetDate));
}
