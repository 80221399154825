import * as React from 'react';
import {Box, Button, Grid, MenuItem, OutlinedInput, Select, Typography} from '@mui/material';
import {
  Editor, EditorProvider, ContentEditableEvent, BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar
} from 'react-simple-wysiwyg';
import {getEmailTemplate, updateEmailTemplate} from '../../../services/EmailTemplateService';
import {enqueueSnackbar} from 'notistack';
import {EmailTemplateModel} from '../../../types/EmailTemplateModel';
import {AppLayout} from '../../AppLayout';
import {Loading} from '../../../components/Loading';
import {useRef} from "react";
import {GenericTooltip} from "../../../components/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export interface Option {
  key: number;
  value: string;
  text: string
}

const options: Option[] = [
  {key: -1, value: 'Macros Variables', text: 'Macros Variables'},
  {key: 1, value: '{CLIENT_FIRST_NAME}', text: 'Client First Name'},
  {key: 2, value: '{STAFF_LIST}', text: 'Staff List'},
  {key: 3, value: '{DUE_DATE}', text: 'Due Date'},
  {key: 4, value: '{HR_NAME}', text: 'HR Name'}
];

interface Props {
  isLocked: boolean,
  onSelect: (value: string) => void
}

export const VariablesDropdown: React.FC<Props> = ({isLocked, onSelect}) => {

  const handleSelectVariable = (event: any) => {
    if (event.target.value === options[0].value) return;
    const option = options.find(o => o.key === event.target.value);
    if (!option) return;
    onSelect(option.value);
  }

  return (
    <Select
      disabled={isLocked}
      sx={{
        maxWidth: '150px', height: '19px', borderRadius: 0,
        '.MuiOutlinedInput-notchedOutline': {borderColor: 'unset !important'}
      }}
      MenuProps={{
        variant: 'selectedMenu',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }}
      value={-1}
      onChange={handleSelectVariable}
    >
      {options.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          <Box sx={{height: '30px', fontSize: '13px', display: 'flex'}}>
            <span style={{width: '120px', marginTop: '2px'}}>{option.text}</span>
            {option.key !== -1 &&
              <GenericTooltip
                title={'Select to insert a macros ' + option.value + ' at current cursor position'}
                placement={"right"}
                icon={<HelpOutlineIcon style={{cursor: 'pointer', marginLeft: 2, width: 18, color: '#777'}}/>}/>}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}

export default function EmailTemplatePage() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLocked, setIsLocked] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [subject, setSubject] = React.useState('');
  const [content, setContent] = React.useState('');
  const wrapperEditorRef = useRef(null);

  React.useEffect(() => {
    setIsLoading(true);
    getEmailTemplate()
      .then((data: EmailTemplateModel) => {
        setSubject(data.subject)
        setContent(data.content);
      })
      .finally(() => setIsLoading(false));
  }, [setSubject, setContent]);

  function onChange(e: ContentEditableEvent) {
    !isDirty && setIsDirty(true);
    setContent(e.target.value);
  }

  function handleSave(): void {
    setIsSaving(true);
    setIsLocked(true);
    const data: EmailTemplateModel = {
      subject: subject,
      content: content
    };

    updateEmailTemplate(data)
      .then(() => {
        enqueueSnackbar("Email template has been updated successfully!", {variant: 'success'});
        setIsDirty(false);
      })
      .finally(() => {
        setIsSaving(false);
      })
  }

  const handleReplaceSelection = (value: string) => {
    // @ts-ignore
    const editorNode = wrapperEditorRef.current.childNodes[0].childNodes[1];
    if (!editorNode) return;

    const selection = window.getSelection();
    if (selection && editorNode.contains(selection.focusNode)) {
      const range = selection.getRangeAt(0);

      const newNode = document.createTextNode(value);
      range.deleteContents();
      range.insertNode(newNode);

      range.selectNode(newNode);
      selection.removeAllRanges();
      selection.addRange(range);

      editorNode.dispatchEvent(new Event('input', {bubbles: true}));
    }
  }

  return (
    <AppLayout>
      {isLoading &&
        <Box sx={{position: 'absolute', width: '200px', height: '200px', top: '50%', left: '50%'}}>
          <Loading/>
        </Box>
      }
      <Grid container rowSpacing={2} columns={10}>
        <Grid item md={1} xs={10}>
          <Typography>
            Subject:
          </Typography>
        </Grid>
        <Grid item md={9} xs={10}>
          <OutlinedInput size='small' sx={{width: '100%'}} value={subject} disabled={isLocked || isSaving}
                         onChange={e => setSubject(e.target.value)}/>
        </Grid>
        <Grid item md={1} xs={10}>
          <Typography>
            Content:
          </Typography>
        </Grid>
        <Grid item md={9} xs={10} ref={wrapperEditorRef}>
          <EditorProvider>
            <Editor
              disabled={isLocked || isSaving}
              value={content}
              containerProps={{
                style: {
                  minHeight: '300px',
                  backgroundColor: '#fff',
                  color: (isLocked ? '#999' : '#000')
                }
              }}
              onChange={onChange}>
              <Toolbar style={{overflowX: 'auto', overflowY: 'hidden'}}>
                <BtnUndo/>
                <BtnRedo/>
                <Separator/>
                <BtnBold/>
                <BtnItalic/>
                <BtnUnderline/>
                <BtnStrikeThrough/>
                <Separator/>
                <BtnNumberedList/>
                <BtnBulletList/>
                <Separator/>
                <BtnLink/>
                <BtnClearFormatting/>
                <HtmlButton/>
                <Separator/>
                <BtnStyles/>
                <Separator/>
                <VariablesDropdown isLocked={isLocked} onSelect={handleReplaceSelection}/>
              </Toolbar>
            </Editor>
          </EditorProvider>
        </Grid>
        <Grid item container justifyContent='flex-end'>
          {isLocked ?
            <Button variant='contained' onClick={() => setIsLocked(false)}>Unlock to edit</Button>
            :
            <Button variant='contained' onClick={handleSave}
                    disabled={isSaving}>{isSaving ? 'Saving...' : 'Save'}</Button>
          }
        </Grid>
      </Grid>
    </AppLayout>
  );
}