import React from "react";
import {closeSnackbar, SnackbarProvider, TransitionProps} from "notistack";
import {Slide} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const DEFAULT_MAX_SNACK = 5;

interface Props {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Transition(props: TransitionProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Slide {...props} direction="left"/>;
}

export const SnackbarMessageProvider: React.FC<Props> = ({children}) => {

  return (
    <SnackbarProvider
      maxSnack={DEFAULT_MAX_SNACK}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      hideIconVariant
      dense={false}
      TransitionComponent={Transition}
      action={(snackbarId) => (
        <CloseIcon fontSize={'small'} sx={{cursor: 'pointer'}} onClick={() => {
          // dismiss the snackbar
          closeSnackbar(snackbarId);
        }
        }/>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
