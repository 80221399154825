import {useState} from "react";
import {Grid, MenuItem, Select, Typography} from "@mui/material";
import { useStaffsLocations } from "../../hooks/useStaffsLocations";

type Props = {
  onChange: (location: string) => void
};

const row = {
  marginBottom: '25px'
}

export function LocationDropdown(props: Props) {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [items, setItems] = useState<string[]>([]);
  useStaffsLocations(setItems);
  
  const handleOnChange = (value: string) => {
    setSelectedValue(value);
    props.onChange(value);
  }

  return (
    <Grid container style={row}>
      <Grid item lg={1.5} sm={3} xs={12}>
        <Typography>
          Location:
        </Typography>
      </Grid>
      <Grid item md={4} sm={5} xs={12}>
        <Select
          displayEmpty
          fullWidth
          size='small'
          value={selectedValue}
          onChange={(event) => handleOnChange(event.target.value)}
        >
            <MenuItem key='All' value='' selected>(All)</MenuItem>
          {items.map((item, key) => {
            return (
              <MenuItem key={key} value={item}>{item}</MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
};
