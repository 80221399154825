import React from 'react'
import {Box, MenuItem, Select} from "@mui/material";

interface Props {
  initStatus: boolean,
  onChangeStatus: (status: boolean) => void
}

const PERIOD_STATUS_LIST = [
  {key: true, value: "Active"},
  {key: false, value: "Inactive"}
];

export const PeriodStatusDropdown: React.FC<Props> = ({initStatus, onChangeStatus}) => {
  return (
    <Select
      sx={{
        width: "100%",
        height: "40px"
      }}
      value={initStatus}
      onChange={(event) => onChangeStatus(event.target.value === "true")}
    >
      {PERIOD_STATUS_LIST.map((status, index) => {
        return (
          <MenuItem
            key={index} value={status.key.toString()}
          >
            <Box sx={{textAlign: 'center'}}>
              {status.value}
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  )
}