import TableCell from '@mui/material/TableCell';
import {Button} from '@mui/material';
import {SortOrderTypes} from '../../../../types/DatatableTypes';
import React from "react";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

interface Props {
  text?: string,
  sortOrder?: SortOrderTypes,
  onSort?: (sortBy?: string) => void,
  columnName?: string
}

export const SortableTableHeadCell: React.FC<Props> = (
  {text, sortOrder, onSort, columnName}
) => {
  const onClickSort = () => {
    onSort && onSort(columnName!);
  }
  return (
    <TableCell align="left">
      {
        (onSort && !!columnName) ?
          (
            <>
              <Button sx={{color: 'black', cursor: 'pointer'}} onClick={onClickSort}>{text}
                {
                  sortOrder && (sortOrder === 'asc'
                    ? <NorthIcon sx={{ml: 1, width: 15}}/>
                    : <SouthIcon sx={{ml: 1, width: 15}}/>)
                }
              </Button>
            </>
          ) :
          (
            text
          )}
    </TableCell>);
}