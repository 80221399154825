import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { FeedbackPeriod } from "../../types/FeedbackPeriod";
import { toDate, toPeriodString } from "../../commons/dates";
import { retrieveFeedbackPeriods } from "../../services/FeedbackPeriodService";

type Props = {
  onChange: (periodId?: number) => void,
  disabled?: boolean,
  defaultValue?: number
};

const row = {
  marginBottom: '25px'
}

export const FeedbackPeriodDropdown: React.FC<Props> = ({ onChange, disabled, defaultValue }) => {
  const [periodId, setPeriodId] = useState<number | undefined | ''>('');
  const [periods, setPeriods] = useState<FeedbackPeriod[]>([]);

  useEffect(() => {
    if (periods.length === 0) {
      retrieveFeedbackPeriods(true)
        .then((res) => {
          const sortedPeriods = res.sort((a, b) => {
            // sort asc
            return toDate(a.endDate).setHours(0, 0, 0, 0) -
              toDate(b.endDate).setHours(0, 0, 0, 0);
          });
          setPeriods(sortedPeriods);
          const defaultId = defaultValue || (sortedPeriods.length > 0 ? sortedPeriods[sortedPeriods.length - 1].id : undefined);
          setPeriodId((defaultId));
          onChange(defaultId);
        })
        .catch((err) => {
          if (periods.length > 0) {
            setPeriods([]);
          }
        })
    }
  }, [periods, setPeriods, onChange, defaultValue]);

  const handleChange = (newPeriodId: number) => {
    setPeriodId(newPeriodId);
    onChange(newPeriodId);
  };

  return (
    <Grid container test-id={'feedback-period'} style={row}>
      <Grid item lg={1.5} sm={3} xs={12}>
        <Typography style={{ marginTop: '7px' }}>
          Feedback Period:
        </Typography>
      </Grid>
      <Grid item md={4} sm={5} xs={12}>
        <Select
          displayEmpty
          disabled={disabled || periods.length === 0}
          fullWidth
          size='small'
          value={periodId}
          onChange={(event) => handleChange(Number(event.target.value))}
        >
          {periods.length === 0 ?
            <MenuItem value=''>Loading...</MenuItem>
            :
            (periods.map((period, key) => {
              const periodString = toPeriodString(period.startDate, period.endDate);
              return (
                <MenuItem key={key} value={period.id} disabled={!period.isActive}>
                  {periodString}
                </MenuItem>
              );
            }))
          }
        </Select>
      </Grid>
    </Grid>
  );
};
