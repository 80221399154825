import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import {Timer, Article, AssignmentInd } from '@mui/icons-material';
import {useNavigate} from "react-router";
import { useLocation } from 'react-router-dom';
import { useAppUser } from '../../hooks/useAppUser';
import { AppUserRole } from '../../types/AppUserModel';

type SubMenuItemModel = {
  title: string,
  icon: React.ReactNode,
  path: string
}

type NavMenuItemModel = {
  title: string,
  path?: string,
  subItems?: SubMenuItemModel[]
}

function getMenuList(role: AppUserRole): NavMenuItemModel[] {
  const menuList: NavMenuItemModel[] = [
    {
      title: 'Staff Feedback',
      path: '/',
    }
  ];

  if (role === AppUserRole.Admin)
  {
    menuList.push({
      title: 'Organiser',
      path: '/organiser'
    });

    menuList.push({
      title: 'Admin',
      subItems: [
        { title: 'Define Period', icon: <Timer />, path: '/admin/periods' },
        { title: 'Define Email Template', icon: <Article />, path: '/admin/email-template'},
        { title: 'Manage List of Admin', icon: <AssignmentInd />, path: '/admin/administrators' },
      ],
    });
  }

  return menuList;
}

const menuItemStyles = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const ACTIVE_MENU_ITEM_BACKGROUND_COLOR: string = 'rgba(255,255,255,0.1)';
const NORMAL_MENU_ITEM_BACKGROUND_COLOR: string = 'transparent';  

export default function Navigator(props: DrawerProps) {
  const {...other} = props;

  const [menuList, setMenuList] = React.useState<NavMenuItemModel[]>([]);

  const {appUser} = useAppUser();
  const location = useLocation();

  const navigate = useNavigate();
  const directTo = (href: string) => {
    navigate(href)
  }
  
  React.useEffect(() => {
    console.log('appUser', appUser)
    if (appUser)
    {
      const menu = getMenuList(appUser?.role);
      setMenuList(menu);
    }
  },[appUser, setMenuList]);

  const getMenuItemBackgroundColor = (itemPath: string) => {
    if((itemPath === '/' && location.pathname === '/') || (itemPath !== '/' && location.pathname.toLowerCase().startsWith(itemPath)))
    {
      return ACTIVE_MENU_ITEM_BACKGROUND_COLOR;
    }

    return NORMAL_MENU_ITEM_BACKGROUND_COLOR;
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem onClick={() => directTo('/')}
                  sx={{...menuItemStyles, ...itemCategory, fontSize: 22, color: '#fff', cursor: 'pointer'}}>
          <ListItemIcon>
            <HomeIcon/>
          </ListItemIcon>
          <ListItemText>Feedback Planner</ListItemText>
        </ListItem>
        {menuList.map((item: NavMenuItemModel) => (
          <Box key={item.title} sx={{bgcolor: '#341e3f'}}>
              {
                !!item.path ? 
                <ListItem sx={{px:0, backgroundColor: getMenuItemBackgroundColor(item.path)}} >
                  <ListItemButton sx={{color: '#fff'}} onClick={() => directTo(item.path!)}>
                  <ListItemText>{item.title}</ListItemText>
                  </ListItemButton>
                </ListItem>
                :
                <ListItem sx={{py: 1, px: 2}} disablePadding>
                  <ListItemText sx={{color: '#fff'}}>{item.title}</ListItemText>
                </ListItem>
              }
            {item.subItems && item.subItems.map((subItem: SubMenuItemModel) => (
              <ListItem disablePadding key={subItem.title} sx={{backgroundColor: getMenuItemBackgroundColor(subItem.path)}}>
                <ListItemButton sx={{...menuItemStyles}} onClick={() => directTo(subItem.path)}>
                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                  <ListItemText>{subItem.title}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{mt: (item.subItems && item.subItems.length > 0 ? 1 : 0)}}/>
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
