import axios from "axios";
import { ClientSummaryModel } from "../types/ClientSummaryModel";
import {SubmitFeedbackRequest, SubmitFeedbackResponse,FeedbackRequest, PeerFeedbackUpdateModel, ClientFeedbackUpdateModel, ClientSummaryUpdateModel} from "../types/FeedbackRequest";
import { StaffRequestSummaryModel } from "../types/StaffRequestSummaryModel";
import { EmailTemplateModel } from "../types/EmailTemplateModel";

export const submitFeedbackRequests = async (body: SubmitFeedbackRequest): Promise<string> => {
  return axios.post('/FeedbackRequests', body)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const addFeedbackRequestsByAdmin = async (staffId: number, body: SubmitFeedbackRequest): Promise<string> => {
  return axios.post('/StaffRequestFeedbackRequests?staffId=' + staffId, body)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const getStaffRequestSummaries = async (periodId: number, location?: string, sortBy?: string, sortOrder?: string): Promise<StaffRequestSummaryModel[]> => {
  return axios.get(`/StaffRequestFeedbackRequests/GetSummaries?periodId=${periodId}&location=${location}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const getFeedbackRequestsByStaff = async (periodId: number, staffId: number): Promise<FeedbackRequest[]> => {
  return axios.get(`/StaffRequestFeedbackRequests/GetListByStaff?periodId=${periodId}&staffId=${staffId}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const getStaffAssignedSummaries = async (periodId: number, location?: string, sortBy?: string, sortOrder?: string): Promise<StaffRequestSummaryModel[]> => {
  return axios.get(`/StaffAssignedFeedbackRequests/GetSummaries?periodId=${periodId}&location=${location}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const getAssignedFeedbackRequestsToStaff = async (periodId: number, staffId: number): Promise<FeedbackRequest[]> => {
  return axios.get(`/StaffAssignedFeedbackRequests/GetListByStaff?periodId=${periodId}&staffId=${staffId}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const retrieveFeedbackRequests = async (periodId: number): Promise<SubmitFeedbackResponse[]> => {
  return axios.get('/FeedbackRequests', {params: {periodId: periodId}})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    })
}

export const updatePeerFeedbackRequest = async (id: number, data: PeerFeedbackUpdateModel): Promise<string> => {
  return axios.put('/StaffRequestFeedbackRequests/' + id + '/update-peer-feedback', data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const updateClientFeedbackRequest = async (id: number, data: ClientFeedbackUpdateModel): Promise<string> => {
  return axios.put('/StaffRequestFeedbackRequests/' + id + '/update-client-feedback', data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const deleteFeedbackRequest = async (id: number): Promise<string> => {
  return axios.delete('/FeedbackRequests/' + id)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

//// START: Client summary 

export const getClientSummaries = async (periodId: number, sortBy?: string, sortOrder?: string): Promise<ClientSummaryModel[]> => {
  return axios.get(`/ClientSummaryFeedbackRequests/GetSummaries?periodId=${periodId}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const getFeedbackRequestsByClient = async (periodId: number, clientEmail: string): Promise<FeedbackRequest[]> => {
  return axios.get(`/ClientSummaryFeedbackRequests/GetListByClient?periodId=${periodId}&clientEmail=${clientEmail}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw new Error(err);
    });
}

export const updateClientSummary = async (data: ClientSummaryUpdateModel): Promise<string> => {
  return axios.put('/ClientSummaryFeedbackRequests', data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    });
}

export const getGeneratedEmail = async (periodId: number, clientEmail: string): Promise<EmailTemplateModel> => {
  return axios.get(`/ClientSummaryFeedbackRequests/GetGeneratedEmail?periodId=${periodId}&clientEmail=${clientEmail}`)
    .then((res) => res.data);
}

//// END: Client summary 