import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { HeaderValue} from "../../types/HeaderValue";
import {useNavigate} from "react-router";
import { useMsal } from "@azure/msal-react";
import { Link } from '@mui/material';

const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
  onDrawerToggle: () => void;
}

const defaultHeaderValue: HeaderValue = {
  path: '/',
  data: {
    title: 'Staff Feedback',
    navItems: []
  }
};

const headerValues : HeaderValue[] = [
  {
    path: '/organiser',
    data: {
      title: 'Organiser',
      navItems: []
    }
  },
  {
    path: '/admin/users',
    data: {
      title: 'Admin',
      navItems: [
        {href: '/', label:'FeedbackRequestPage view'}
      ]
    }
  },
  {
    path: '/admin/periods',
    data: {
      title: 'Define Periods',
      navItems: []
    }
  },
  {
    path: '/admin/email-template',
    data: {
      title: 'Define Email Template',
      navItems: []
    }
  },
  {
    path: '/admin/administrators',
    data: {
      title: 'Administrators Management',
      navItems: []
    }
  }
];

export default function Header(props: HeaderProps) {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const { onDrawerToggle } = props;

  const getUserDisplayName = () => accounts && accounts.length > 0 ? accounts[0].name : '';

  const handleLogout = () => {
      instance.logoutRedirect({
          postLogoutRedirectUri: "/",
      });
  };

  // @ts-ignore
  const header: HeaderValue = headerValues.find((e) => window.location.pathname.toLowerCase().startsWith(e.path)) ?? defaultHeaderValue;

  const handleClick = (href: string) => {
    navigate(href)
  }

  return (
    <React.Fragment>
       <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            
            <Grid item>
              <Typography variant='subtitle1'>
              Welcome, {getUserDisplayName()}
            </Typography>
            </Grid>
            <Grid item>
               <Link
                onClick={handleLogout}
                href="#"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
              >
                Signout
              </Link> 
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar> 
      
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs style={{paddingTop: 0}}>
              <Typography color="inherit" variant="h6" component="h1">
                {header.data.title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {
        header.data.navItems && header.data.navItems.length > 1 &&
        (<AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
          <Tabs value={0} textColor="inherit">
            {
              header.data.navItems.map((item, key) => {
                return (
                  <Tab label={item.label} onClick={() => handleClick(item.href)} />
                );
              })
            }
          </Tabs>
        </AppBar>)
      }
    </React.Fragment>
  );
}
