import axios from "axios";
import {Administrator, NewAdministratorRequest} from "../types/Administrators";

export const retrieveAdministrators = async (): Promise<Administrator[]> => {
  return axios.get('/Users/Admins')
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const createNewAdministrator = async (body: NewAdministratorRequest): Promise<String> => {
  return axios.post('/Users/Admins',body)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    })
}

export const deleteAdministrator = async (id: string): Promise<String> => {
  return axios.delete('/Users/Admins/' + id)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    })
}