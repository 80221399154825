import { useState, useEffect } from "react";
import { AppUserModel } from "../types/AppUserModel";

function getStorageValue<T>(key: string, defaultValue: T) {
  const data : string | null = localStorage.getItem(key);
  return data ? JSON.parse(data) as T : defaultValue;
}

export const useAppUser = () => {
  const key = '_APP_USER_';
  const [value, setValue] = useState<AppUserModel | undefined>();

  useEffect(() => {
    const storedValue = getStorageValue<AppUserModel | undefined>(key, undefined);
    if (storedValue) {
        setValue(storedValue);
    }
  }, []);

  useEffect(() => {
    if (value)
    {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value]);

  return {appUser: value, setAppUser: setValue};
};
