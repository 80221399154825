import React, {useMemo, useState} from 'react'
import {InputAdornment, ListSubheader, MenuItem, Select, TextField} from "@mui/material";
import {Staff} from "../../../../../types/Staff";
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  staffs: Staff[],
  selectedId: number,
  onSelect: (event: any) => void
}

export const StaffsDropdown: React.FC<Props> = ({staffs, selectedId, onSelect}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const filteredStaffs = useMemo(
    () => staffs.filter((staff) => {
      const name = staff.firstName + ' ' + staff.lastName + (staff.preferredName ? ` (${staff.preferredName})` : '');
      return containsText(name, searchTerm);
    }), [searchTerm, staffs]);

  const renderValue = (selectedId: number) => {
    if (selectedId === -1) {
      return '--- Select a name ---';
    }
    const staff =  staffs.find((item) => item.id === selectedId);
    return staff
      ? staff.firstName + ' ' + staff.lastName + (staff.preferredName ? ` (${staff.preferredName})` : '')
      : '';
  }

  return (
    <Select
      sx={{width: '100%', minWidth: '250px'}}
      size='small'
      name="selectedId"
      value={selectedId}
      onChange={(event) => onSelect(event)}
      onClose={() => setSearchTerm("")}
      renderValue={() => renderValue(selectedId)}
      MenuProps={{
        autoFocus: false,
        PaperProps: {
          style: {width: '250px', height: '300px', overflowY: 'scroll'}
        }
      }}
    >
      <ListSubheader>
        <TextField
          size="small"
          autoFocus
          placeholder="Type to search..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
              setSearchTerm('');
            }
          }}
        />
      </ListSubheader>
      <MenuItem value={-1}>--- Select a name ---</MenuItem>
      {filteredStaffs.map((staff, key) => {
        return (
          <MenuItem
            key={key}
            value={staff.id}
          >
            {staff.firstName + ' ' + staff.lastName + (staff.preferredName ? ` (${staff.preferredName})` : '')}
          </MenuItem>
        );
      })}
    </Select>
  )
}