import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { FeedbackRequest } from '../../../../../types/FeedbackRequest';
import { LinearProgress } from '@mui/material';
import { ReadonlyTableRow } from './ReadonlyTableRow';
import { EditableTableRow } from './EditableTableRow';
import { useState } from 'react';
import { deleteFeedbackRequest } from '../../../../../services/FeedbackRequestService';
import { enqueueSnackbar } from 'notistack';
import { useConfirm } from "material-ui-confirm";

type ChildTableProps = {
  isLoading: boolean,
  feedbackRequests: FeedbackRequest[],
  onSaved: () => void,
  onDeleted: () => void
}

export function ChildTable(props: ChildTableProps) {
  const { isLoading, feedbackRequests } = props;

  return (
    <TableContainer sx={{ backgroundColor: '#fafafa' }}>
      <Table size="small" aria-label="table">
        <TableBody>
          {
            isLoading ?
              <>
                <TableRow>
                  <TableCell colSpan={4}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </>
              :
              feedbackRequests.map((item) => (
                <ChildTableRow key={item.id} item={item} onSaved={props.onSaved} onDeleted={props.onDeleted} />
              ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type ChildTableRowProps = {
  item: FeedbackRequest,
  onSaved: () => void,
  onDeleted: () => void
}

export function ChildTableRow(props: ChildTableRowProps) {
  const { item } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const confirm = useConfirm();

  const handleSaved = () => {
    setIsEditMode(false);
    props.onSaved();
  };

  const handleDelete = () => {
    confirm({ title: 'Delete', description: "Are you sure you want to delete this feedback request?" })
      .then(() => {
        proceedDelete();
      });
  }

  const proceedDelete = () => {
    setIsDeleting(true);
    deleteFeedbackRequest(item.id!)
      .then((res) => {
        enqueueSnackbar('Delete feedback request successfully!', { variant: 'success' });
        props.onDeleted();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }

  return (
    <>
      {isEditMode ?
        <EditableTableRow
          item={item}
          onSaved={handleSaved}
          onCancelClick={() => setIsEditMode(false)}
          onDeleteClick={handleDelete}
          isDeleting={isDeleting} />
        :
        <ReadonlyTableRow
          item={item}
          onEditClick={() => setIsEditMode(true)}
          onDeleteClick={handleDelete}
          isDeleting={isDeleting} />}
    </>
  );
}