import React, {useState} from "react";
import {FeedbackPeriod} from "../../../../../types/FeedbackPeriod";
import {PeriodEditModeRow} from "./PeriodEditModeRow";
import {PeriodViewModeRow} from "./PeriodViewModeRow";

interface Props {
  row: FeedbackPeriod,
  onRefreshData: () => void,
  onCheckConsistentPeriod: (start: Date, end: Date) => boolean,
  captureEditState: (value: boolean) => void
}

export const PeriodRowContainer: React.FC<Props> = (
  {row, onRefreshData, onCheckConsistentPeriod, captureEditState}
) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const onChangeEditModeState = (value: boolean) => {
    setIsEditMode(value);
    captureEditState(value);
  }

  return (
    <>
      { isEditMode ?
        (<PeriodEditModeRow
          row={row}
          onCancel={() => onChangeEditModeState(false)}
          onCheckConsistentPeriod={onCheckConsistentPeriod}
          onViewMode={() => onChangeEditModeState(false)}
          onRefreshData={onRefreshData}/>) :
        (<PeriodViewModeRow
          row={row}
          onEditMode={() => onChangeEditModeState(true)}
          onRefreshData={onRefreshData}/>)
      }
    </>
  );
}