export enum AppUserRole {
  None = 0,
  Staff = 1,
  Admin = 2
}

export interface AppUserModel {
  userId: string;
  name: string;
  role: AppUserRole;
  staffId: number;
}