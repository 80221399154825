import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { FeedbackRequest, FeedbackRequestType } from '../../../../../types/FeedbackRequest';
import { Button } from '@mui/material';

type ReadonlyTableRowProps = { 
  item: FeedbackRequest, 
  onEditClick: () => void, 
  onDeleteClick: () => void,
  isDeleting: boolean
}

export function ReadonlyTableRow (props: ReadonlyTableRowProps) {
  const { item } = props;

  return (
      <TableRow key={item.id}>
        <TableCell component="th" scope="row">
          {item.requestType === FeedbackRequestType.Peer ? item.peerStaffFullName : item.clientName}
        </TableCell>
        <TableCell>{item.clientCompany}</TableCell>
        <TableCell>{item.projectName}</TableCell>
        <TableCell>{item.clientEmail}</TableCell>
        <TableCell align="right" sx={{}}>
          {props.isDeleting === false &&
          <Button size="small" onClick={() => props.onEditClick() }>Edit</Button> }
          <Button size="small" onClick={props.onDeleteClick}>{props.isDeleting ? 'Deleting...' : 'Delete'}</Button>
        </TableCell>
      </TableRow>
  );
}