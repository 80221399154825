import React, {useEffect, useState} from 'react'
import {enqueueSnackbar} from "notistack";
import {Staff} from "../../../../../types/Staff";
import {NewAdministratorRequest} from "../../../../../types/Administrators";
import {createNewAdministrator} from "../../../../../services/AdministratorService";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {NewAdministratorDialogMobileView} from "./NewAdministratorDialogMobileView";
import {NewAdministratorDialogDesktopView} from "./NewAdministratorDialogDesktopView";

interface Props {
  staffs: Staff[],
  isOpenDialog: boolean,
  onCloseDialog: () => void,
  onRefreshData: () => void,
  isSmallScreen: boolean
}

export const NewAdministratorDialog: React.FC<Props> = (
  {staffs, isOpenDialog, onCloseDialog, onRefreshData, isSmallScreen}
) => {

  const [newAdmin, setNewAdmin] = useState<NewAdministratorRequest>({email: '', fullName: '', staffId: -1});
  const [canAdd, setCanAdd] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setCanAdd(isValidNewItem(newAdmin));
    }, 200);
    return () => clearTimeout(timeOutId);
  }, [newAdmin]);

  const isValidNewItem = (item: NewAdministratorRequest) => {
    return item['staffId'] !== -1
      && item['email'] !== ''
      && item['fullName'] !== '';
  }

  const onSelect = (event: any) => {
    const {value} = event.target;
    const selectedStaff = staffs.find((staff) => staff.id === value);
    if (selectedStaff) {
      const fullName = selectedStaff.firstName + ' ' + selectedStaff.lastName + (selectedStaff.preferredName
        ? ` (${selectedStaff.preferredName})` : '');
      setNewAdmin({email: selectedStaff.email, staffId: selectedStaff.id, fullName: fullName});
    }
  };

  const handleAddNewAdministrator = () => {
    setDisabled(true);
    createNewAdministrator(newAdmin)
      .then((res) => {
        enqueueSnackbar('The user role has been updated as administrator successfully', {variant: 'success'});
        onCloseDialog();
        onRefreshData();
      })
      .catch(() => setDisabled(false));
  }

  const handleCloseDialog = (event: object, reason: string) => {
    if (reason !== 'backdropClick') {
      onCloseDialog();
    }
  }

  return (
    <Dialog open={isOpenDialog} onClose={handleCloseDialog} maxWidth="md" fullScreen={isSmallScreen}>
      <DialogTitle>Add New Administrator</DialogTitle>
      <DialogContent>
        {
          isSmallScreen
          ? <NewAdministratorDialogMobileView
              staffs={staffs}
              selectedId={newAdmin.staffId}
              onSelect={onSelect}
              onCloseDialog={onCloseDialog}
              canAdd={canAdd} disabled={disabled} handleAddNewAdministrator={handleAddNewAdministrator}/>
          : <NewAdministratorDialogDesktopView
              staffs={staffs}
              selectedId={newAdmin.staffId}
              onSelect={onSelect}
              onCloseDialog={onCloseDialog}
              canAdd={canAdd} disabled={disabled} handleAddNewAdministrator={handleAddNewAdministrator}/>
        }
      </DialogContent>
    </Dialog>
  )
}