import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "../../authConfig";
import { Paper } from '@mui/material';
import WindowIcon from '@mui/icons-material/Window';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        CodeHQ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignInPage() {
    const { instance } = useMsal();

  const handleSignin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    instance.loginRedirect(loginApiRequest)
            .catch((e) => {
                console.log(e);
            });
  };

  return (
    <Box sx={{display: 'flex', minHeight: '100vh', backgroundColor: '#fafafa'}}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper sx={{p: 2, mt: 5}}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Staff Feedback Planner
          </Typography>
          <Box component="form" onSubmit={handleSignin} noValidate sx={{ mt: 1 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <WindowIcon sx={{mr: 1}} />
               Sign in with Microsoft
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        </Paper>
      </Container>
    </Box>
  );
}