import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { FeedbackRequest } from '../../../../types/FeedbackRequest';
import { LinearProgress } from '@mui/material';

type ChildTableProps = {
  isLoading: boolean, 
  feedbackRequests: FeedbackRequest[]
}

export function ChildTable (props: ChildTableProps) {
  const { isLoading, feedbackRequests } = props;

  return (
    <TableContainer  sx={{backgroundColor: '#fafafa'}}>
      <Table size="small" aria-label="table">
        <TableBody>
        {
          isLoading ?
          <>
          <TableRow>
            <TableCell colSpan={2}>
            <LinearProgress />
          </TableCell>
          </TableRow>
          </>
          :
        feedbackRequests.map((item) => (
            <ChildTableRow key={item.id} item={item} />
        ))
        }
        </TableBody>
      </Table>
      </TableContainer>
  );
}

export function ChildTableRow (props: { item: FeedbackRequest }) {
  const { item } = props;

  return (
    <>
      <TableRow key={item.id}>
        <TableCell component="th" scope="row" sx={{width: '15%'}}>
          {item.requestorStaffFullName}
        </TableCell>
        <TableCell>{item.projectName}</TableCell>
      </TableRow>
    </>
  );
}