import {Staff} from "../types/Staff";
import {useEffect} from "react";
import {retrieveStaffs} from "../services/StaffService";

export const useStaffs = (
  setStaffs: (state: Staff[]) => void,
): void => {
  useEffect(() => {
    retrieveStaffs()
      .then((res) => {
        const sortedStaffs = res.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setStaffs(sortedStaffs);
      })
      .catch((err) => {
        setStaffs([]);
    })
  }, [setStaffs])
}