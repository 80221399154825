import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FeedbackRequestBox } from '../../../../components/FeedbackRequestBox/FeedbackRequestBox';

type AddFeedbackRequestDialogProps = {
  isOpen: boolean,
  onClose: () => void,
  onSubmitted: () => void,
  periodId: number,
  staffId: number,
  staffName: string,
}

export function AddFeedbackRequestDialog (props: AddFeedbackRequestDialogProps) {
  
  const handleClose = (event?: any, reason?: "backdropClick" | "escapeKeyDown") => {
    if (!reason)
    {
      props.onClose();
    }
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={handleClose} maxWidth="xl">
        <DialogTitle>Add Staff Feedback Requests</DialogTitle>
        <DialogContent>
          <FeedbackRequestBox
            staffId={props.staffId} 
            staffName={props.staffName}
            isInAddDialog={true}
            fixedPeriodId={props.periodId} 
            onCancel={() => handleClose()}
            onSubmitted={props.onSubmitted} />
        </DialogContent>
      </Dialog>
    </>
  );
}
