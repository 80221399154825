import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ClientFeedbackUpdateModel, FeedbackRequest, FeedbackRequestType, PeerFeedbackUpdateModel } from '../../../../../types/FeedbackRequest';
import { Button, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useState } from 'react';
import { Staff } from '../../../../../types/Staff';
import { useStaffs } from '../../../../../hooks/useStaffs';
import { WarningTooltip } from '../../../../../components/Tooltip';
import { updateClientFeedbackRequest, updatePeerFeedbackRequest } from '../../../../../services/FeedbackRequestService';
import { enqueueSnackbar } from 'notistack';
import { isValidEmail } from '../../../../../utils/ValidationHelpers';
import { InputContainerBox } from '../../../../../components/InputContainerBox';
import { useConfirm } from 'material-ui-confirm';
import { ConfirmCancelMessage } from '../../common/ConfirmCancelMessage';

type EditableTableRowProps = {
  item: FeedbackRequest,
  onSaved: () => void,
  onCancelClick: (isDirty: boolean) => void,
  onDeleteClick: () => void,
  isDeleting: boolean
}

export function EditableTableRow(props: EditableTableRowProps) {
  const { item } = props;

  const confirm = useConfirm();

  const handleCancelClick = (isDirty: boolean) => {
    if (isDirty) {
      confirm({ title: 'Cancel', description: ConfirmCancelMessage })
        .then(() => props.onCancelClick(isDirty));
    }
    else {
      props.onCancelClick(isDirty);
    }
  }

  return (
    <>
      {item.requestType === FeedbackRequestType.Peer
        ?
        <PeerEditableTableRow
          item={item}
          onSaved={props.onSaved}
          onCancelClick={handleCancelClick}
          onDeleteClick={props.onDeleteClick}
          isDeleting={props.isDeleting} />
        :
        <ClientEditableTableRow
          item={item}
          onSaved={props.onSaved}
          onCancelClick={handleCancelClick}
          onDeleteClick={props.onDeleteClick}
          isDeleting={props.isDeleting} />
      }
    </>
  );
}

const dropdownStyles = {
  width: '80%'
}

function PeerEditableTableRow(props: EditableTableRowProps) {
  const { item } = props;
  const [staffId, setStaffId] = useState<number>(item.peerStaffId!);
  const [clientCompany, setClientCompany] = useState<string>(item.clientCompany);
  const [projectName, setProjectName] = useState<string>(item.projectName);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [staffs, setStaffs] = useState<Staff[]>([]);

  useStaffs(setStaffs);

  const handleSaveClick = () => {
    if (isInputsValid() === false) {
      return;
    }

    const data: PeerFeedbackUpdateModel = {
      peerStaffId: Number(staffId),
      clientCompany: clientCompany,
      projectName: projectName
    };

    setIsSaving(true);
    updatePeerFeedbackRequest(item.id!, data)
      .then((res) => {
        enqueueSnackbar('Update peer feedback request successfully!', { variant: 'success' });
        props.onSaved();
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const handleCancelClick = () => {
    const isDirty = staffId !== item.peerStaffId || clientCompany !== item.clientCompany || projectName !== item.projectName;
    props.onCancelClick(isDirty);
  }

  const isInputDisabled = () => isSaving || props.isDeleting;

  const isInputsValid = () => {
    return !!clientCompany && !!projectName;
  }

  return (
    <TableRow key={item.id}>
      <TableCell component="th" scope="row" sx={{ width: 400 }}>
        {staffs.length === 0 ?
          <div>Loading...</div> :
          <Select
            disabled={isInputDisabled()}
            style={dropdownStyles}
            size='small'
            value={staffId}
            onChange={(event) => setStaffId(Number(event.target.value))}
          >
            {staffs.map((peer, key) => {
              return (
                <MenuItem
                  key={key}
                  value={peer.id}
                >
                  {peer.firstName + ' ' + peer.lastName + (peer.preferredName ? ` (${peer.preferredName})` : '')}
                </MenuItem>
              );
            })}
          </Select>
        }
      </TableCell>
      <TableCell>
        <InputContainerBox>
          <OutlinedInput
            disabled={isInputDisabled()}
            size='small'
            value={clientCompany}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setClientCompany(event.target.value)}
          />
          {!clientCompany && <WarningTooltip title={"Mandatory field."} />}
        </InputContainerBox>
      </TableCell>
      <TableCell>
        <InputContainerBox>
          <OutlinedInput
            disabled={isInputDisabled()}
            size='small'
            value={projectName}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setProjectName(event.target.value)}
          />
          {!projectName && <WarningTooltip title={"Mandatory field."} />}
        </InputContainerBox>
      </TableCell>
      <TableCell />
      <TableCell align="right">
        <Button size="small" onClick={() => handleSaveClick()} disabled={isInputDisabled() || isInputsValid() === false}>{isSaving ? 'Saving...' : 'Save'}</Button>
        <Button size="small" onClick={handleCancelClick} disabled={isInputDisabled()}>Cancel</Button>
        <Button size="small" disabled={isInputDisabled()} onClick={props.onDeleteClick}>{props.isDeleting ? 'Deleting...' : 'Delete'}</Button>
      </TableCell>
    </TableRow>
  );
}

function ClientEditableTableRow(props: EditableTableRowProps) {
  const { item } = props;
  const [clientName, setClientName] = useState<string>(item.clientName!);
  const [clientCompany, setClientCompany] = useState<string>(item.clientCompany);
  const [projectName, setProjectName] = useState<string>(item.projectName);
  const [clientEmail, setClientEmail] = useState<string>(item.clientEmail!);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSaveClick = () => {
    if (isInputsValid() === false) {
      return;
    }

    const data: ClientFeedbackUpdateModel = {
      clientName: clientName,
      clientEmail: clientEmail,
      clientCompany: clientCompany,
      projectName: projectName
    };

    setIsSaving(true);
    updateClientFeedbackRequest(item.id!, data)
      .then((res) => {
        enqueueSnackbar('Update client feedback request successfully!', { variant: 'success' });
        props.onSaved();
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const handleCancelClick = () => {
    const isDirty = clientName !== item.clientName || clientCompany !== item.clientCompany || projectName !== item.projectName || clientEmail !== item.clientEmail;
    props.onCancelClick(isDirty);
  }

  const isInputDisabled = () => isSaving || props.isDeleting;

  const isInputsValid = () => {
    return !!clientName && !!clientCompany && !!projectName && isValidEmail(clientEmail);
  }

  return (
    <TableRow key={item.id}>
      <TableCell component="th" scope="row" sx={{ width: 400 }}>
        <InputContainerBox>
          <OutlinedInput
            disabled={isInputDisabled()}
            size='small'
            value={clientName}
            name="clientName"
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setClientName(event.target.value)} />
          {!clientName && <WarningTooltip title={"Mandatory field."} />}
        </InputContainerBox>
      </TableCell>
      <TableCell>
        <InputContainerBox>
          <OutlinedInput
            disabled={isInputDisabled()}
            size='small'
            value={clientCompany}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setClientCompany(event.target.value)}
          />
          {!clientCompany && <WarningTooltip title={"Mandatory field."} />}
        </InputContainerBox>
      </TableCell>
      <TableCell>
        <InputContainerBox>
          <OutlinedInput
            disabled={isInputDisabled()}
            size='small'
            value={projectName}
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setProjectName(event.target.value)}
          />
          {!projectName && <WarningTooltip title={"Mandatory field."} />}
        </InputContainerBox>
      </TableCell>
      <TableCell>
        <InputContainerBox>
          <OutlinedInput
            disabled={isInputDisabled()}
            size='small'
            value={clientEmail}
            name="clientEmail"
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setClientEmail(event.target.value)} />
          {!clientEmail ?
            (<WarningTooltip title={"Mandatory field."} />) :
            (isValidEmail(clientEmail) || (<WarningTooltip title={"Email is invalid."} />))}
        </InputContainerBox>
      </TableCell>
      <TableCell align="right">
        <Button size="small" onClick={() => handleSaveClick()} disabled={isInputDisabled() || isInputsValid() === false}>{isSaving ? 'Saving...' : 'Save'}</Button>
        <Button size="small" onClick={handleCancelClick} disabled={isInputDisabled()}>Cancel</Button>
        <Button size="small" disabled={isInputDisabled()} onClick={props.onDeleteClick}>{props.isDeleting ? 'Deleting...' : 'Delete'}</Button>
      </TableCell>
    </TableRow>
  );
}