import React from "react";
import { useAppUser } from "../../hooks/useAppUser";
import { Loading } from "../../components/Loading";
import { FeedbackRequestBox } from "../../components/FeedbackRequestBox/FeedbackRequestBox";
import { AppLayout } from "../AppLayout";

export const FeedbackRequestPage: React.FC = () => {
  const { appUser } = useAppUser();

  return (
    <AppLayout>
      {!appUser ?
        <Loading />
        :
        <FeedbackRequestBox
          staffId={appUser!.staffId}
          staffName={appUser!.name} />
      }
    </AppLayout>
  );
}