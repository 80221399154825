import axios from "axios";
import { EmailTemplateModel } from "../types/EmailTemplateModel";

export const getEmailTemplate = async (): Promise<EmailTemplateModel> => {
  return axios.get('/EmailTemplates/GetSingle')
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data ?? {};
    });
}

export const updateEmailTemplate = async (data: EmailTemplateModel): Promise<string> => {
  return axios.put('/EmailTemplates/UpdateSingle', data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    });
}