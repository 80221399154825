import React, {useCallback, useEffect, useState} from "react";
import {AdministratorsListTable} from "./components/AdministratorsListTable";
import {Box} from "@mui/material";
import {NewAdministratorDialog} from "./components/NewAdministratorDialog";
import {Administrator} from "../../../types/Administrators";
import {retrieveAdministrators} from "../../../services/AdministratorService";
import {Staff} from "../../../types/Staff";
import {useStaffs} from "../../../hooks/useStaffs";
import {SortOrderTypes} from "../../../types/DatatableTypes";
import useMediaQuery from "@mui/material/useMediaQuery";
import {ResponsiveAddButton} from "../components/ResponsiveAddButton";
import {AppLayout} from "../../AppLayout";

interface Props {}

export const AdministratorsPage: React.FC<Props> = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [admins, setAdmins] = useState<Administrator[]>([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [staffs, setStaffs] = useState<Staff[]>([])
  useStaffs(setStaffs);
  const [sortOrder, setSortOrder] = useState<SortOrderTypes>('');
  const [sortBy, setSortBy] = useState<string>('Name');
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const loadData = useCallback((force: boolean) => {
    if (force || (admins.length === 0 && count === 0)) {
      setSortOrder('desc');
      setIsLoading(true);
      retrieveAdministrators()
        .then((res) => {
          const sortedAdmins = res.sort((a, b) =>
            a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()));
          setAdmins(sortedAdmins);
          setIsLoading(false);
          setCount(1);
        });
    }
  }, [admins, count]);

  const onSort = (newSortBy?: string) => {
    let newSortOrder: SortOrderTypes = 'desc';
    if (newSortBy === sortBy) {
      newSortOrder = sortOrder === '' ? 'desc' : (sortOrder === 'asc' ? 'desc' : 'asc');
    }
    setSortBy(newSortBy!);
    setSortOrder(newSortOrder);
  }

  const effectSort = useCallback(() => {
    const sortAdmins = [];
    if (sortOrder === "asc") {
      if (sortBy === 'Name') {
        sortAdmins.push(...admins.sort((a, b) =>
          b.fullName.toLowerCase().localeCompare(a.fullName.toLowerCase())));
      } else {
        sortAdmins.push(...admins.sort((a, b) =>
          b.email.toLowerCase().localeCompare(a.email.toLowerCase())));
      }
    } else {
      if (sortBy === 'Name') {
        sortAdmins.push(...admins.sort((a, b) =>
          a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase())));
      } else {
        sortAdmins.push(...admins.sort((a, b) =>
          a.email.toLowerCase().localeCompare(b.email.toLowerCase())));
      }

    }
    setAdmins(sortAdmins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, sortBy]);

  useEffect(() => {
    effectSort();
  },[effectSort]);

  useEffect(() =>{
    loadData(false);
  },[loadData]);

  return (
    <AppLayout>
      <Box>
        <ResponsiveAddButton isSmallScreen={isSmallScreen} onClickAction={setOpenDialog}/>
        {openDialog &&
          <NewAdministratorDialog
            staffs={staffs}
            isOpenDialog={openDialog}
            onCloseDialog={() => setOpenDialog(false)}
            onRefreshData={() => loadData(true)}
            isSmallScreen={isSmallScreen}
          />
        }
        <AdministratorsListTable
          admins={admins}
          isLoading={isLoading}
          onRefreshData={() => loadData(true)}
          sortOrder={sortOrder}
          onSort={onSort}
          sortBy={sortBy}
        />
      </Box>
    </AppLayout>
  );
};
