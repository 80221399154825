import React from "react";
import { Administrator } from "../../../../../types/Administrators";
import TableCell from "@mui/material/TableCell";
import { Button } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { enqueueSnackbar } from "notistack";
import { deleteAdministrator } from "../../../../../services/AdministratorService";
import { useAppUser } from "../../../../../hooks/useAppUser";
import { useConfirm } from "material-ui-confirm";

interface Props {
  row: Administrator,
  onRefreshData: () => void,
}

export const AdministratorRow: React.FC<Props> = ({ row, onRefreshData }) => {
  const { appUser } = useAppUser();
  const confirm = useConfirm();

  const handleDeleteAdministrator = () => {
    confirm({ title: 'Delete administrator', description: 'Are you sure you want to delete this administrator?' })
      .then(() => {
        deleteAdministrator(row.id)
          .then((res) => {
            enqueueSnackbar('The administrator has been deleted successfully', { variant: 'success' });
            onRefreshData();
          });
      });
  }

  return (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        <span style={{marginLeft: '10px'}}>
          {row.fullName} {appUser?.staffId === row.staffId ? (<strong>(You)</strong>) : ''}
        </span>
      </TableCell>
      <TableCell align="left">
        <span style={{marginLeft: '10px'}}>
          {row.email}
        </span>
      </TableCell>
      <TableCell align="right">
        <Button size="small" onClick={handleDeleteAdministrator}>Delete</Button>
      </TableCell>
    </TableRow>
  );
}