import { useState } from "react";
import {
  Grid,
  Typography
} from "@mui/material";
import { PeerFeedbackRequestDesktopView } from "./PeerFeedbackRequestDesktopView";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PeerFeedbackRequestMobile } from "./PeerFeedbackRequestMobileView";
import { FeedbackRequest } from "../../../../types/FeedbackRequest";
import { Staff } from "../../../../types/Staff";
import { useStaffs } from "../../../../hooks/useStaffs";
import { GenericTooltip } from "../../../Tooltip";

interface Props {
  items: FeedbackRequest[],
  add: () => void,
  apply: (index: number, event: any) => void,
  remove: (index: number) => void,
  readonly: boolean,
  duplicates: any,
  canSubmit: boolean
}

const row = {
  marginBottom: '10px'
}

export const PeerFeedbackRequestContainer = (props: Props) => {
  const isSmallScreen = useMediaQuery('(max-width:500px)');

  const [peers, setPeers] = useState<Staff[]>([]);
  useStaffs(setPeers);

  return (
    <Grid container test-id={'peer-feedback'} style={row}>
      <Grid container test-id={'peer-feedback-title'}>
        <Typography>
          I would like peer feedback from:
        </Typography>
        <GenericTooltip
          title='Please select the person that you have worked with during this period.'
          placement={"right"}
          icon={<HelpOutlineIcon style={{ cursor: 'pointer', marginLeft: 2, width: 18, color: '#777' }} />} />
      </Grid>
      <Grid container test-id={'peer-feedback-content'} style={row}>
        {isSmallScreen ? <PeerFeedbackRequestMobile peers={peers} {...props} /> : <PeerFeedbackRequestDesktopView peers={peers} {...props} />}
      </Grid>
    </Grid>
  );
};
