import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { FeedbackRequest } from '../../../../types/FeedbackRequest';
import { Button, LinearProgress } from '@mui/material';
import { useState } from 'react';
import { deleteFeedbackRequest } from '../../../../services/FeedbackRequestService';
import { enqueueSnackbar } from 'notistack';
import { useConfirm } from "material-ui-confirm";

type ChildTableProps = {
  isLoading: boolean,
  feedbackRequests: FeedbackRequest[],
  onDataChanged: () => void
}

export function ChildTable(props: ChildTableProps) {
  const { isLoading, feedbackRequests } = props;

  return (
    <TableContainer sx={{ backgroundColor: '#fafafa' }}>
      <Table size="small" aria-label="table">
        <TableBody>
          {
            isLoading ?
              <>
                <TableRow>
                  <TableCell colSpan={4}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </>
              :
              feedbackRequests.map((item) => (
                <ChildTableRow key={item.id} item={item} onDataChanged={props.onDataChanged} />
              ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ChildTableRow(props: { item: FeedbackRequest, onDataChanged: () => void }) {
  const { item } = props;
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const confirm = useConfirm();

  const handleDelete = () => {
    confirm({ title: 'Delete', description: "Are you sure you want to delete this assigned request?" })
      .then(() => {
        proceedDelete();
      });
  }

  const proceedDelete = () => {
    setIsDeleting(true);
    deleteFeedbackRequest(item.id!)
      .then((res) => {
        enqueueSnackbar('Delete feedback request successfully!', { variant: 'success' });
        props.onDataChanged();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }

  return (
    <>
      <TableRow key={item.id}>
        <TableCell component="th" scope="row">
          {item.requestorStaffFullName}
        </TableCell>
        <TableCell>{item.clientCompany}</TableCell>
        <TableCell>{item.projectName}</TableCell>
        <TableCell align="right" sx={{}}>
          <Button size="small" onClick={handleDelete}>{isDeleting ? 'Deleting...' : 'Delete'}</Button>
        </TableCell>
      </TableRow>
    </>
  );
}