import {useEffect} from "react";
import { getStaffsLocations } from "../services/StaffService";

export const useStaffsLocations = (
  setStaffsLocations: (state: string[]) => void,
): void => {
  useEffect(() => {
    getStaffsLocations()
      .then((res) => {
        setStaffsLocations(res);
      })
      .catch((err) => {
        setStaffsLocations([]);
    })
  }, [setStaffsLocations])
}