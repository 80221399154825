import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {StaffsDropdown} from "../SatffsDropdown";
import {Button} from "@mui/material";
import {Staff} from "../../../../../types/Staff";

interface Props {
  staffs: Staff[],
  selectedId: number,
  onSelect: (event: any) => void,
  onCloseDialog: () => void,
  canAdd: boolean,
  disabled: boolean,
  handleAddNewAdministrator: () => void
}

export const NewAdministratorDialogMobileView: React.FC<Props> = (
  {staffs, selectedId, onSelect, onCloseDialog, canAdd, disabled, handleAddNewAdministrator}
) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={1}>
        <StaffsDropdown staffs={staffs} selectedId={selectedId} onSelect={onSelect}/>
        <Box/>
        <Button
          variant="contained"
          onClick={onCloseDialog}
        >
          <strong>Cancel</strong>
        </Button>
        <Button
          disabled={!canAdd || disabled}
          variant="contained"
          onClick={handleAddNewAdministrator}
        >
          <strong>Add</strong>
        </Button>
      </Stack>
    </Box>
  );
}