import React, {useCallback, useEffect, useState} from 'react'
import {Dialog, DialogContent} from "@mui/material";
import {FeedbackPeriod} from "../../../../../types/FeedbackPeriod";
import {
  API_DATE_TIME_PATTERN,
  formatDate,
  toDate,
  today,
  tomorrow
} from "../../../../../commons/dates";
import {isEqual} from "date-fns";
import {createNewFeedbackPeriod} from "../../../../../services/FeedbackPeriodService";
import {enqueueSnackbar} from "notistack";
import DialogTitle from "@mui/material/DialogTitle";
import {NewPeriodDialogDesktopView} from "./NewPeriodDialogDesktopView";
import {NewPeriodDialogMobileView} from "./NewPeriodDialogMobileView";
import {useConfirm} from "material-ui-confirm";
import {ConfirmCancelMessage} from "../../../../OrganiserPage/components/common/ConfirmCancelMessage";

interface Props {
  isOpenDialog: boolean,
  onCloseDialog: () => void,
  onCheckConsistentPeriod: (start: Date, end: Date) => boolean,
  onRefreshData: () => void,
  isSmallScreen: boolean
}

export const NewPeriodDialog: React.FC<Props> = (
  {isOpenDialog, onCloseDialog, onCheckConsistentPeriod, onRefreshData, isSmallScreen}
) => {

  const confirm = useConfirm();
  const [newPeriod, setNewPeriod] = useState<FeedbackPeriod>({
    id: 0,
    startDate: formatDate(today(), API_DATE_TIME_PATTERN),
    endDate: formatDate(tomorrow(), API_DATE_TIME_PATTERN),
    isActive: true
  });
  const [canAdd, setCanAdd] = useState(
    onCheckConsistentPeriod(toDate(newPeriod.startDate), toDate(newPeriod.endDate))
  );

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setCanAdd(onCheckConsistentPeriod(toDate(newPeriod.startDate), toDate(newPeriod.endDate)));
    }, 200);
    return () => clearTimeout(timeOutId);
  }, [newPeriod, onCheckConsistentPeriod]);

  const handleStartDateChange = useCallback(
    (newDate: Date | null) => {
      if (!newDate || isEqual(newDate, toDate(newPeriod.startDate))) {
        return;
      }
      setNewPeriod((prev) => {
        return {...prev, startDate: formatDate(newDate, API_DATE_TIME_PATTERN)}
      });
    },
    [newPeriod.startDate],
  );

  const handleEndDateChange = useCallback(
    (newDate: Date | null) => {
      if (!newDate || isEqual(newDate, toDate(newPeriod.endDate))) {
        return;
      }
      setNewPeriod((prev) => {
        return {...prev, endDate: formatDate(newDate, API_DATE_TIME_PATTERN)}
      });
    },
    [newPeriod.endDate],
  );

  const handleStatusChange = useCallback(
    (status: boolean) => {
      setNewPeriod((prev) => {
        return {...prev, isActive: status}
      });
    },
    [],
  );

  const handleAddNewPeriod = () => {
    setDisabled(true);
    createNewFeedbackPeriod({
      periodId: newPeriod.id,
      startDate: newPeriod.startDate,
      endDate: newPeriod.endDate,
      isActive: newPeriod.isActive
    })
      .then(() => {
        enqueueSnackbar('The period has been created successfully', {variant: 'success'});
        onCloseDialog();
        onRefreshData();
      })
      .catch(() => setDisabled(false));
  }

  const handleCloseDialog = (event: object, reason: string) => {
    if (reason !== 'backdropClick') {
      onCloseDialog();
    }
  }

  const onCloseDialogWithConfirm = () => {
    const isNotEdited = isEqual(toDate(newPeriod.startDate).setHours(0,0,0,0), today().setHours(0,0,0,0))
      && isEqual(toDate(newPeriod.endDate).setHours(0,0,0,0), tomorrow().setHours(0,0,0,0));
    if (isNotEdited) {
      onCloseDialog();
    } else {
      confirm({ title: 'Cancel', description: ConfirmCancelMessage })
        .then(() => onCloseDialog());
    }
  }

  return (
    <Dialog open={isOpenDialog} onClose={handleCloseDialog} maxWidth="md" fullScreen={isSmallScreen}>
      <DialogTitle>Add New Period</DialogTitle>
      <DialogContent>
        {
          isSmallScreen
            ? <NewPeriodDialogMobileView
              newStartDate={newPeriod.startDate}
              handleStartDateChange={handleStartDateChange}
              newEndDate={newPeriod.endDate}
              handleEndDateChange={handleEndDateChange}
              isActive={newPeriod.isActive}
              handleStatusChange={handleStatusChange}
              onCloseDialog={onCloseDialogWithConfirm}
              handleAddNewPeriod={handleAddNewPeriod}
              canAdd={canAdd}
              disabled={disabled}/>
            : <NewPeriodDialogDesktopView
              newStartDate={newPeriod.startDate}
              handleStartDateChange={handleStartDateChange}
              newEndDate={newPeriod.endDate}
              handleEndDateChange={handleEndDateChange}
              isActive={newPeriod.isActive}
              handleStatusChange={handleStatusChange}
              onCloseDialog={onCloseDialogWithConfirm}
              handleAddNewPeriod={handleAddNewPeriod}
              canAdd={canAdd}
              disabled={disabled}/>
        }
      </DialogContent>
    </Dialog>
  )
}